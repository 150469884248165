import { Icons } from "@components/icons";
import * as Auth from "@services/auth.service";
import { useUserStore } from "@store/user.store";
import { Button } from "@ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import { UserCircle } from "iconoir-react";
import { LogOut } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

export function UserNav({ className }: React.HTMLAttributes<HTMLElement>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUserStore();
  const logoutHandler = async () => {
    await Auth.logout();
    navigate("/login");
    toast.success(t("login_success-logout-msg"));
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        data-testid="user-menu"
        className={`w-full ${className}`}
        asChild
      >
        <div>
          <div className="hidden w-full justify-between md:flex sidebar-desktop-username">
            <div className="text-white text-left pointer-events-none cursor-none truncate font-display">
              <div className="text-lg font-bold">
                {user?.customerInfo?.firstName} {user?.customerInfo?.lastName}
              </div>
              <div className="text-xs opacity-55">{user?.company}</div>
            </div>
            <Button variant="ghost" className="text-white p-1">
              <Icons.more />
            </Button>
          </div>
          <div className="flex md:hidden justify-center sidebar-mobile-username ">
            <Button
              variant="ghost"
              className="flex justify-center items-center text-white p-4 rounded-full bg-brand hover:bg-brand"
            >
              <div className="text-lg w-full leading-none" id="username-nav">
                {user?.customerInfo?.firstName}
              </div>
            </Button>
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuGroup>
          <Link to="profile">
            <DropdownMenuItem>
              <UserCircle className="mr-2 size-5" />
              <span>{t("dashboard-menu-settings")}</span>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
        <DropdownMenuItem data-testid="logout" onClick={logoutHandler}>
          <LogOut className="mr-2 size-5" />
          <span>{t("account-navigation_logout")}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
