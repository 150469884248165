import { Icons } from "@components/icons";
import { Link } from "react-router-dom";

const SidebarBadge = () => {
  return (
    <div className="h-40 flex justify-center items-center">
      <Link to="/">
        <Icons.cncLogo id="sidebar-logo" />
      </Link>
    </div>
  );
};

export default SidebarBadge;
