import * as AuthAccess from "@access/auth.access";
import { SignupPayload } from "@access/auth.access";
import {
  OATH_RESPONSE_FAILED,
  OATH_RESPONSE_OK,
  OAUTH_SIGN_UP_RESPONSE,
} from "@models/auth";
import { User } from "@models/user";

export async function login(option: {
  email: string;
  password: string;
}): Promise<{ error: OATH_RESPONSE_FAILED | null; data: OATH_RESPONSE_OK | null }> {
  return AuthAccess.login(option);
}
export async function sign_up_auth0(option: {
  email: string;
  password: string;
  server_token?: string;
}): Promise<{ error: OATH_RESPONSE_FAILED | null; data: OAUTH_SIGN_UP_RESPONSE | null }> {
  return AuthAccess.sign_up_auth0(option);
}
export async function sign_up(
  option: SignupPayload,
  token: string,
  urlParams: any,
): Promise<{ error: OATH_RESPONSE_FAILED | null; data: OAUTH_SIGN_UP_RESPONSE | null }> {
  return AuthAccess.sign_up(option, token, urlParams);
}
export async function getUser(): Promise<{ data: User | null; error?: string }> {
  return AuthAccess.getUser();
}
export async function resetPassword({
  email,
}: {
  email: string;
}): Promise<{ data: string | undefined; status: number }> {
  return AuthAccess.resetPassword({ email });
}

export async function logout(): Promise<void> {
  return AuthAccess.logout();
}
export async function sessionCheck(): Promise<{
  sessionAvailablity: boolean;
  token: string | undefined;
}> {
  return AuthAccess.sessionCheck();
}
