import {
  CreateFABatchOrderResponse,
  FrameworkAgreementOrder,
  FrameworkAgremenetLine,
} from "@models/framework-agreement.model";
import { Addresses, DefaultContacts, OrderDetails } from "@models/offer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface FALineItemState extends FrameworkAgremenetLine {
  selected: boolean;
  selectedOption: number;
  calculatedTotalPrice: number;
  setQuantity: number;
}

export type Steps = "1" | "2" | "3" | "4";
type StepStatus = {
  [key in Steps]: boolean;
};
export interface FaCerticate {
  offerLineId: string;
  quantity: number;
}
interface WizzardState {
  data: FALineItemState[] | null;
  order: OrderDetails;
  defaultContacts: DefaultContacts;
  addresses: Addresses;
  warningOnSelectedDeliveryDate: boolean;
  setWarningOnSelectedDeliveryDate: (value: boolean) => void;
  frameworkAgreements: FrameworkAgreementOrder[] | null;
  wizzardState: {
    currentStep: Steps;
    stepsStatuses: StepStatus;
  };
  setCurrentStep: (value: Steps) => void;
  addLineItems: (offerLines: FrameworkAgremenetLine[]) => void;
  changeSelected: (uuid: string, selectedIdx: number) => void;
  handleSelectOfLineItem: <T extends keyof FALineItemState>(
    lineId: string,
    value: FALineItemState[T],
    orderProperty: T,
  ) => void;
  setAllItemLinesAsSelected: () => void;
  changeDefaultContact: <T extends keyof DefaultContacts>(
    contactType: T,
    value: DefaultContacts[T],
  ) => void;
  changeAddress: <T extends keyof Addresses>(
    addressPath: T,
    property: keyof Addresses[T],
    value: Addresses[T][keyof Addresses[T]],
  ) => void;
  setOrderDetails: <T extends keyof OrderDetails>(
    orderProperty: T,
    value: OrderDetails[T],
  ) => void;
  setStepStatus: (step: Steps, value: boolean) => void;
  orderStatePayload: CreateFABatchOrderResponse | null;
  setOrderStatePayload: (payload: CreateFABatchOrderResponse | null) => void;
  reset: () => void;
  setFrameworkDetails: (newFrameworkAgreement: FrameworkAgreementOrder[]) => void;
  certifactes: FaCerticate[] | [];
  setCerficateLines: (certifactes: FaCerticate[]) => void;
}

export const useFrameworkAgreementWizzardState = create<WizzardState>()(
  devtools(
    immer((set) => ({
      wizzardState: {
        currentStep: "1",
        stepsStatuses: {
          1: false,
          2: false,
          3: false,
          4: false,
        },
      },
      orderStatePayload: null,
      data: null,
      frameworkAgreements: null,
      warningOnSelectedDeliveryDate: false,
      order: {
        comments: "",
        deliveryDate: "",
        files: [],
        orderLines: [],
        reference: "",
        totalPrice: 0,
      },
      defaultContacts: {
        contactInvoicing: "",
        contactPurchasing: "",
        contactOrderConfirmation: "",
      },
      certifactes: [],
      addresses: {
        billingAddress: {
          city: "",
          companyName: "",
          country: "",
          postalCode: "",
          street: "",
          additionalInfo: "",
          taxNumber: "",
        },
        shippingAddress: {
          city: "",
          companyName: "",
          country: "",
          postalCode: "",
          street: "",
          additionalInfo: "",
        },
      },
      setCerficateLines: (certifactes) => {
        set(
          (state: WizzardState) => {
            state.certifactes = certifactes;
          },
          false,
          "setCerficateLines",
        );
      },
      setCurrentStep(value) {
        set(
          (state: WizzardState) => {
            state.wizzardState.currentStep = value;
          },
          false,
          "setCurrentStep",
        );
      },
      setOrderStatePayload: (payload) => {
        set(
          (state: WizzardState) => {
            state.orderStatePayload = payload;
          },
          false,
          "setOrderStatePayload",
        );
      },
      reset: () => {
        set(
          (state: WizzardState) => {
            state.wizzardState.currentStep = "1";
            state.wizzardState.stepsStatuses = {
              1: false,
              2: false,
              3: false,
              4: false,
            };
            state.orderStatePayload = null;
            state.data = null;
            state.order = {
              comments: "",
              deliveryDate: "",
              files: [],
              orderLines: [],
              reference: "",
              totalPrice: 0,
            };
            state.warningOnSelectedDeliveryDate = false;
            state.defaultContacts = {
              contactInvoicing: "",
              contactPurchasing: "",
              contactOrderConfirmation: "",
            };
            state.addresses = {
              billingAddress: {
                city: "",
                companyName: "",
                country: "",
                postalCode: "",
                street: "",
                additionalInfo: "",
                taxNumber: "",
              },
              shippingAddress: {
                city: "",
                companyName: "",
                country: "",
                postalCode: "",
                street: "",
                additionalInfo: "",
              },
            };
          },
          false,
          "reset",
        );
      },
      setStepStatus(step, value) {
        set(
          (state: WizzardState) => {
            state.wizzardState.stepsStatuses[step] = value;
          },
          false,
          "setStepStatus",
        );
      },
      changeAddress: (addressPath, property, value) => {
        set(
          (state: WizzardState) => {
            state.addresses[addressPath][property] = value;
          },
          false,
          "changeAddress",
        );
      },
      setWarningOnSelectedDeliveryDate: (value) => {
        set(
          (state: WizzardState) => {
            state.warningOnSelectedDeliveryDate = value;
          },
          false,
          `setWarningOnSelectedDeliveryDate`,
        );
      },
      changeDefaultContact: (contactType, value) => {
        set(
          (state: WizzardState) => {
            state.defaultContacts = { ...state.defaultContacts, [contactType]: value };
          },
          false,
          "changeDefaultContact",
        );
      },
      addLineItems: (offerLines) => {
        set(
          () => ({
            data: offerLines.map((offerLine) => ({
              ...offerLine,
              // if only one item then set it true
              selected: true,
              selectedOption: 0,
              setQuantity: offerLine.quantityPerBatches,
              calculatedTotalPrice: offerLine.quantityPerBatches * offerLine.unitPrice,
            })),
          }),
          false,
          "addLineItems",
        );
      },
      changeSelected: (uuid: string, selectedIdx: number) => {
        set(
          (state: WizzardState) => {
            const offerLine = state.data?.find((offerLine) => offerLine.uuid === uuid);
            if (offerLine) {
              offerLine.selectedOption = selectedIdx;
            }
          },
          false,
          "changeSelected",
        );
      },
      handleSelectOfLineItem: (lineId, value, lineProperty) => {
        set(
          (state: WizzardState) => {
            const offerLine = state.data?.find(
              (offerLine) => offerLine.frameworkLineId === lineId,
            );
            if (offerLine) {
              offerLine[lineProperty] = value;
              if (lineProperty === "setQuantity") {
                offerLine.calculatedTotalPrice = offerLine.unitPrice * value;
              }
            }
          },
          false,
          "handleSelectOfLineItem",
        );
      },
      setAllItemLinesAsSelected: () => {
        set(
          (state: WizzardState) => {
            state.data?.forEach((offerLine) => (offerLine.selected = true));
          },
          false,
          "setAllItemLinesAsSelected",
        );
      },
      setOrderDetails: (orderProperty, value) => {
        set(
          (state: WizzardState) => {
            state.order = { ...state.order, [orderProperty]: value };
          },
          false,
          `setOrderDetails/${orderProperty}`,
        );
      },
      setFrameworkDetails: (newFrameworkAgreement) => {
        set(
          (state: WizzardState) => {
            state.frameworkAgreements = newFrameworkAgreement;
          },
          false,
          `setFrameworkDetails`,
        );
      },
    })),
    { store: "wizzard-fa-store" },
  ),
);
