export class NumberUtils {
  /**
   * Formats a number with thousands separator and decimal comma.
   *
   * @param {number} num the number to format
   * @returns {string} the formatted number
   */
  static format(num: number): string {
    // Split number into integer and decimal parts
    const parts = num.toString().split(".");
    // Add thousands separator to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // If there is a decimal part, add it with a comma separator
    if (parts.length === 2) {
      parts[1] = parts[1].replace(".", ",");
      // Ensure decimal part has two digits
      if (parts[1].length === 1) {
        parts[1] += "0";
      }
    }

    return parts.join(",");
  }
}
