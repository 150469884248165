import { FrameworkAgreementStatus } from "@models/framework-agreement.model";
import { OfferStatus } from "@models/offer";
import { badgeVariants } from "@ui/badge";
import { VariantProps } from "class-variance-authority";

export function getColorForOfferStatus(
  status: OfferStatus,
): VariantProps<typeof badgeVariants>["variant"] {
  const variant = {
    Open: "yellow",
    Offeraccepted: "green",
    ReadyForLP: "yellow",
    New: "yellow",
    Wizardnotfinished: "secondary",
    DisqualificationOther: "secondary",
    DisqualifiedPrivate: "secondary",
    ProfiCheck: "yellow",
    Waitingforanswer: "light-red",
    Lost: "secondary",
    Offersent: "light-lavender",
    LiveinPortal: "yellow",
    Offerreceived: "yellow",
    InNegotiation: "blue",
    Partnerofferreceived: "yellow",
    UpdateSent: "orange",
    Wizardinprogress: "secondary",
    ReadyforProcessing: "yellow",
  };

  const color = variant[status] || "default";

  // Type assertion to explicitly tell TypeScript that 'color' is of the expected type
  return color as VariantProps<typeof badgeVariants>["variant"];
}
export function getColorForFAStatus(
  status: FrameworkAgreementStatus,
): VariantProps<typeof badgeVariants>["variant"] {
  const variant = {
    Open: "secondary",
    InProgress: "light-lavender",
    SupplierFASent: "secondary",
    FAConfirmed: "light-lavender",
    Cancelled: "secondary",
    Completed: "green",
  };

  const color = variant[status] || "default";

  // Type assertion to explicitly tell TypeScript that 'color' is of the expected type
  return color as VariantProps<typeof badgeVariants>["variant"];
}
