import {
  AcceptOfferData,
  Addresses,
  DefaultContacts,
  FrameworkOfferLines,
  OrderDetails,
  SingleOfferLine,
} from "@models/offer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export type Steps = "1" | "2" | "3";
type StepStatus = {
  [key in Steps]: boolean;
};

interface SingleOfferLineItem extends SingleOfferLine {
  selectedOfferLineId: string;
  hasFaOrder: boolean;
}
interface FrameworkOfferLineItem extends FrameworkOfferLines {
  selectedOfferLineId: string;
  hasSingleOrder: boolean;
}

interface InquiryStore {
  wizzardState: {
    currentStep: Steps;
    stepsStatuses: StepStatus;
  };
  changeDefaultContact: <T extends keyof DefaultContacts>(
    contactType: T,
    value: DefaultContacts[T],
  ) => void;
  order: OrderDetails;
  changeAddress: <T extends keyof Addresses>(
    addressPath: T,
    property: keyof Addresses[T],
    value: Addresses[T][keyof Addresses[T]],
  ) => void;
  setCurrentStep: (value: Steps) => void;
  setStepStatus: (step: Steps, value: boolean) => void;
  setFinalStatus: (payload: AcceptOfferData) => void;
  defaultContacts: DefaultContacts;
  addresses: Addresses;
  singleOfferLines: SingleOfferLineItem[] | [];
  frameworkOfferLines: FrameworkOfferLineItem[] | [];
  orderStatePayload: AcceptOfferData | null;
  setOrderStatePayload: (payload: AcceptOfferData | null) => void;
  setOrderDetails: <T extends keyof OrderDetails>(
    orderProperty: T,
    value: OrderDetails[T],
  ) => void;
  reset: () => void;
  filterOutOfferLines: () => void;
  addSingleOffer: (offer: SingleOfferLine, selectedOfferLineId: string) => void;
  addFrameworkOffer: (offer: FrameworkOfferLines, selectedOfferLineId: string) => void;
  warningOnSelectedDeliveryDate: boolean;
  setWarningOnSelectedDeliveryDate: (value: boolean) => void;
}

export const useInquiry = create<InquiryStore>()(
  devtools(
    immer((set) => ({
      warningOnSelectedDeliveryDate: false,
      order: {
        comments: "",
        deliveryDate: "",
        files: [],
        orderLines: [],
        reference: "",
        totalPrice: 0,
      },
      orderStatePayload: null,
      wizzardState: {
        currentStep: "1",
        stepsStatuses: {
          1: false,
          2: false,
          3: false,
        },
      },
      addresses: {
        billingAddress: {
          city: "",
          companyName: "",
          country: "",
          postalCode: "",
          street: "",
          additionalInfo: "",
          taxNumber: "",
        },
        shippingAddress: {
          city: "",
          companyName: "",
          country: "",
          postalCode: "",
          street: "",
          additionalInfo: "",
        },
      },
      defaultContacts: {
        contactInvoicing: "",
        contactPurchasing: "",
        contactOrderConfirmation: "",
      },
      setFinalStatus: (payload) => {
        set(
          (state: InquiryStore) => {
            state.wizzardState.stepsStatuses["2"] = true;
            state.wizzardState.currentStep = "3";
            state.orderStatePayload = payload;
          },
          false,
          "setFinalStatus",
        );
      },
      setOrderStatePayload: (payload) => {
        set(
          (state: InquiryStore) => {
            state.orderStatePayload = payload;
          },
          false,
          "setOrderStatePayload",
        );
      },
      setWarningOnSelectedDeliveryDate: (value) => {
        set(
          (state: InquiryStore) => {
            state.warningOnSelectedDeliveryDate = value;
          },
          false,
          `setWarningOnSelectedDeliveryDate`,
        );
      },
      reset: () => {
        set(() => ({
          wizzardState: {
            currentStep: "1",
            stepsStatuses: {
              1: false,
              2: false,
              3: false,
            },
          },
          orderStatePayload: null,
          addresses: {
            billingAddress: {
              city: "",
              companyName: "",
              country: "",
              postalCode: "",
              street: "",
              additionalInfo: "",
              taxNumber: "",
            },
            shippingAddress: {
              city: "",
              companyName: "",
              country: "",
              postalCode: "",
              street: "",
              additionalInfo: "",
            },
          },
          defaultContacts: {
            contactInvoicing: "",
            contactPurchasing: "",
            contactOrderConfirmation: "",
          },
          order: {
            comments: "",
            deliveryDate: "",
            files: [],
            orderLines: [],
            reference: "",
            totalPrice: 0,
          },
          singleOfferLines: [],
          frameworkOfferLines: [],
        }));
      },
      setOrderDetails: (orderProperty, value) => {
        set(
          (state: InquiryStore) => {
            state.order = { ...state.order, [orderProperty]: value };
          },
          false,
          `setOrderDetails/${orderProperty}`,
        );
      },
      filterOutOfferLines() {
        set(
          (state: InquiryStore) => {
            const newSingleOfferLines = state.singleOfferLines.map((offer) => {
              return {
                ...offer,

                quantities: offer.quantities.filter(
                  (q) => q.offerLineId === offer.selectedOfferLineId,
                ),
              };
            });
            const newFrameworkOfferLines = state.frameworkOfferLines.map((offer) => {
              return {
                ...offer,
                quantities: offer.quantities.filter(
                  (q) => q.offerLineId == offer.selectedOfferLineId,
                ),
              };
            });
            state.singleOfferLines = newSingleOfferLines;
            state.frameworkOfferLines = newFrameworkOfferLines;
          },
          false,
          "filterOutOfferLines",
        );
      },
      setCurrentStep(value) {
        set(
          (state: InquiryStore) => {
            state.wizzardState.currentStep = value;
          },
          false,
          "setCurrentStep",
        );
      },
      changeAddress: (addressPath, property, value) => {
        set(
          (state: InquiryStore) => {
            state.addresses[addressPath][property] = value;
          },
          false,
          "changeAddress",
        );
      },
      changeDefaultContact: (contactType, value) => {
        set(
          (state: InquiryStore) => {
            state.defaultContacts = { ...state.defaultContacts, [contactType]: value };
          },
          false,
          "changeDefaultContact",
        );
      },
      setStepStatus(step, value) {
        set(
          (state: InquiryStore) => {
            state.wizzardState.stepsStatuses[step] = value;
          },
          false,
          "setStepStatus",
        );
      },
      singleOfferLines: [],
      frameworkOfferLines: [],
      addSingleOffer: (offer, selectedOfferLineId) =>
        set((state) => {
          if (
            state.singleOfferLines.find(
              (fa) => fa.selectedOfferLineId === selectedOfferLineId,
            )
          ) {
            return {
              singleOfferLines: state.singleOfferLines.filter(
                (fa) => fa.selectedOfferLineId !== selectedOfferLineId,
              ),
            };
          } else {
            return {
              singleOfferLines: state.singleOfferLines
                ? [
                    ...state.singleOfferLines,
                    { ...offer, selectedOfferLineId, hasFaOrder: true },
                  ]
                : [{ ...offer, selectedOfferLineId, hasFaOrder: true }],
            };
          }
        }),
      addFrameworkOffer: (offer, selectedOfferLineId) =>
        set((state) => {
          if (
            state.frameworkOfferLines.find(
              (fa) => fa.selectedOfferLineId === selectedOfferLineId,
            )
          ) {
            return {
              frameworkOfferLines: state.frameworkOfferLines.filter(
                (fa) => fa.selectedOfferLineId !== selectedOfferLineId,
              ),
            };
          } else {
            return {
              frameworkOfferLines: state.frameworkOfferLines
                ? [
                    ...state.frameworkOfferLines,
                    { ...offer, selectedOfferLineId, hasSingleOrder: true },
                  ]
                : [{ ...offer, selectedOfferLineId, hasSingleOrder: true }],
            };
          }
        }),
    })),
  ),
);
