import { Icons } from "@components/icons";
import { PAGES_ROUTES } from "@config/routes";
import { useInquiry } from "@store/wizard-inqury";
import { Button } from "@ui/button";
import { Card, CardContent } from "@ui/card";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";

const Confirmation = () => {
  const { orderStatePayload, reset, order } = useInquiry();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col items-center justify-center max-w-2xl m-auto p-6 pb-40 space-y-8">
        <Icons.successIllustration className="h-56 w-56 mb-4" />
        <h1 className="sm:text-4xl text-2xl font-semibold text-center mb-2">
          {t("wizard-order-confirm_heading")}
        </h1>
        <div className="space-y-8 w-full">
          {orderStatePayload?.frameworkAgreementName &&
            orderStatePayload?.frameworkAgreementId && (
              <Card className="w-full bg-muted">
                <CardContent className="p-8">
                  <div className="flex sm:flex-row flex-col gap-4 justify-between">
                    <div>
                      <div className="flex items-center gap-4 mb-2">
                        <span className="sm:text-4xl text-2xl font-bold font-display">
                          {orderStatePayload.frameworkAgreementName}
                        </span>
                      </div>
                      <div className="flex items-center gap-1 mb-2 text-muted-foreground text-sm">
                        <span className="font-medium">{t("dashboard-reference")}:</span>
                        <span className="font-bold font-display">{order.reference}</span>
                      </div>
                    </div>
                    <Link
                      className="sm:self-center self-start flex items-center text-sm text-muted-foreground font-bold font-display"
                      to={PAGES_ROUTES.ORDER_ID_FRAMEWORK_AGREEMENT_OVERVIEW_URL(
                        orderStatePayload.frameworkAgreementId,
                      )}
                    >
                      {t("wizard-order-confirm_view-order-link")}
                      <Icons.chevronRight className="size-4" />
                    </Link>
                  </div>
                </CardContent>
              </Card>
            )}
          {orderStatePayload?.orderName && (
            <Card className="w-full bg-muted">
              <CardContent className="p-8">
                <div className="flex sm:flex-row flex-col gap-4 justify-between">
                  <div>
                    <div className="flex items-center gap-4 mb-2">
                      <span className="sm:text-4xl text-2xl font-bold font-display">
                        {orderStatePayload.orderName}
                      </span>
                    </div>
                    <div className="flex items-center gap-1 mb-2 text-muted-foreground text-sm">
                      <span className="font-medium">{t("dashboard-reference")}:</span>
                      <span className="font-bold font-display">{order.reference}</span>
                    </div>
                  </div>
                  <Link
                    className="sm:self-center self-start flex items-center text-sm text-muted-foreground font-bold font-display"
                    to={PAGES_ROUTES.ORDER_ID(orderStatePayload?.orderId)}
                  >
                    {t("wizard-order-confirm_view-order-link")}
                    <Icons.chevronRight className="size-4" />
                  </Link>
                </div>
              </CardContent>
            </Card>
          )}
        </div>

        <div>
          <p className="font-bold my-4 font-display">{t("dashboard-whats_next")}</p>
          <ul className="space-y-4 text-sm text-muted-foreground">
            <li className="flex gap-4 items-center">
              <div>
                <Icons.arrowRightIcon />
              </div>
              <p>{t("dashboard-whats_next_success_order_1")}</p>
            </li>
            <li className="flex gap-4 items-center">
              <div>
                <Icons.arrowRightIcon />
              </div>
              <p>{t("dashboard-whats_next_success_order_2")}</p>
            </li>
          </ul>
        </div>

        <div className="flex fixed gap-4 sm:p-8 p-6 left-0 sm:left-[4rem] md:left-[16rem] right-0 bottom-0 border-t bg-background md:w-[calc(100%-16rem)] sm:w-[calc(100%-4rem)] w-full z-20 justify-between flex-col sm:flex-row">
          <Button
            onClick={() => {
              reset();
              navigate(PAGES_ROUTES.ORDERS);
            }}
            className="ml-auto my-4"
          >
            {t("dashboard-go_to_overview")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
