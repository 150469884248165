import { Icons } from "@components/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import * as UploadService from "@services/upload.service";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Checkbox } from "@ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { RadioGroup, RadioGroupItem } from "@ui/radio-group";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { v4 } from "uuid";
import * as z from "zod";

// eslint-disable-next-line react-hooks/rules-of-hooks
const conditionsForColobrationList = useObjectTranslations(
  "conditions_for_colobrations_list",
);

const accountDetailsWizard = z.object({
  documentsRequired: z.array(z.string()).optional(),
  uploadDocs: z.string().optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const DocumentsForm = () => {
  const [uploadDocuments, setUploadDocuments] = useState(false);
  const [uploadingDocs, setUploadingDocs] = useState(false);
  const [enableUpload, setEnableUpload] = useState(false);
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    documentsRequired: accountDetails?.documentsRequired || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });
  const documentsRequired_watch = form.watch("documentsRequired");
  useEffect(() => {
    if (documentsRequired_watch && documentsRequired_watch.length > 0) {
      // temporary disable upload
      setUploadDocuments(false);
    } else {
      setUploadDocuments(false);
    }
  }, [documentsRequired_watch]);
  const uploadDocs_watch = form.watch("uploadDocs");
  useEffect(() => {
    if (uploadDocs_watch === "Yes") {
      // temporary disable upload
      setEnableUpload(false);
    } else {
      setEnableUpload(false);
    }
  }, [uploadDocs_watch]);
  async function onSubmit({ documentsRequired }: z.infer<typeof accountDetailsWizard>) {
    setWizardDetails("documentsRequired", documentsRequired);
    setStepStatus("7", true);
    setCurrentStep("8");
  }

  const handleUploadFiles = async (
    files: FileList | null,
    uploadField: "Supplier Questionnaire" | "NDA" | "QSV" | "Code of Conduct",
  ) => {
    const file = files?.[0];
    if (!file) {
      console.error(new Error("No file selected"));
      return;
    }
    try {
      setUploadingDocs(true);
      const payload = {
        recordId: uploadField,
        fileName: file.name,
        file: file,
        uuid: v4(),
        fileType: file.type,
      };
      await UploadService.uploadUrl(payload);
      // form.setValue(`document.${formIndex}.files`, [...allFiles, uploadedFile]);
    } catch (error) {
      toast.error(t("error_form-general"));
    } finally {
      setUploadingDocs(false);
    }
  };
  // TODO: When one of the conditions is selected, we display the uploader for the selected condition
  // Unknows:
  // Whats the format of the file? pdf?
  // Where the file is stored?
  // Whats the interface of the file for the local storage?
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[20vh] sm:min-h-[34vh] mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0">
            <div className="text-destructive text-xs mb-6">
              {" "}
              * {t("dashboard-any_info_your_not_sure")}
            </div>
            <div className="space-y-10">
              <FormField
                control={form.control}
                name="documentsRequired"
                render={() => (
                  <FormItem>
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-are_there_any_specific_conditon_before_colab")}
                    </FormLabel>
                    <div className="flex md:flex-row flex-col gap-4 md:gap-20 pt-4">
                      {Object.entries(conditionsForColobrationList).map(
                        ([code, name]) => (
                          <FormField
                            key={code}
                            control={form.control}
                            name="documentsRequired"
                            render={({ field }) => {
                              return (
                                <FormItem
                                  key={code}
                                  className="flex flex-row items-center space-x-3 space-y-0"
                                >
                                  <FormControl>
                                    <Checkbox
                                      className="h-5 w-5"
                                      checked={field.value?.includes(code)}
                                      onCheckedChange={(checked) => {
                                        return checked
                                          ? field.onChange([
                                              ...(field?.value || []),
                                              code,
                                            ])
                                          : field.onChange(
                                              field.value?.filter(
                                                (value) => value !== code,
                                              ),
                                            );
                                      }}
                                    />
                                  </FormControl>
                                  <FormLabel className="text-sm font-normal">
                                    {name}
                                  </FormLabel>
                                </FormItem>
                              );
                            }}
                          />
                        ),
                      )}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {uploadDocuments && (
                <FormField
                  control={form.control}
                  name="uploadDocs"
                  render={({ field }) => (
                    <FormItem className="space-y-3 !mt-6">
                      <FormLabel className="font-bold font-display text-sm">
                        {t("dashboard-would_you_like_to_upload_docs")}
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          className="flex flex-wrap gap-10"
                        >
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="Yes" />
                            </FormControl>
                            <FormLabel className="font-normal text-sm">
                              {t("global_yes")}
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="No" />
                            </FormControl>
                            <FormLabel className="font-normal text-sm">
                              {t("global_no")}
                            </FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {enableUpload &&
                documentsRequired_watch &&
                documentsRequired_watch.length > 0 && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {documentsRequired_watch.map((value, index) => {
                      return (
                        <div key={index}>
                          <div className="text-sm mb-2">
                            {
                              {
                                "Supplier Questionnaire": t(
                                  "dashboard-supplierquestionnaire",
                                ),
                                NDA: t("dashboard-nda_requirements"),
                                QSV: t("dashboard-qsvrequirements"),
                                "Code of Conduct": t("dashboard-codeofconduct"),
                              }[value]
                            }
                          </div>
                          <div className="relative">
                            <input
                              accept=".pdf"
                              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                              type="file"
                              onChange={(e) =>
                                handleUploadFiles(
                                  e.target.files,
                                  value as
                                    | "Supplier Questionnaire"
                                    | "NDA"
                                    | "QSV"
                                    | "Code of Conduct",
                                )
                              }
                            />
                            <label className="border text-muted-foreground rounded-lg px-4 py-2 flex justify-between items-center space-x-2 cursor-pointer">
                              <span className="text-sm">
                                {t("text_62e6fa20963c5502645ee7bc")}
                              </span>
                              {uploadingDocs ? (
                                <Icons.spinner className="animate-spin size-4 text-brand" />
                              ) : (
                                <Icons.upload className="text-muted-foreground size-4" />
                              )}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-between p-8">
              <Button
                onClick={() => setCurrentStep("6")}
                variant={"outline"}
                type="button"
              >
                {t("global_go-back-btn")}
              </Button>
              <Button>{t("global_continue-btn")}</Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default DocumentsForm;
