import robotHandImage from "@assets/images/sign-up-bg.webp";
import { Icons } from "@components/icons";
import Metadata from "@components/metadata";
import { Button } from "@ui/button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const errors: Record<number, { header: string; description: string; button: string }> = {
  404: {
    header: "404_heading",
    description: "404_description",
    button: "404_button",
  },
  403: {
    header: "403_heading",
    description: "403_description",
    button: "403_button",
  },
};

export default function ErrorPage({ errorCode }: { errorCode: number }) {
  const { t } = useTranslation();
  return (
    <div>
      <Metadata>
        <title>{t(errors[errorCode].header)} | CNC24</title>
      </Metadata>
      <div className="relative flex-col h-screen items-center justify-center md:grid md:max-w-none md:grid-cols-2 md:px-0">
        <div
          className="relative h-58 bg-muted p-6 text-white dark:border-r md:h-full md:p-10 bg-cover"
          style={{ backgroundImage: `url(${robotHandImage})` }}
        >
          <div className="relative z-20 flex flex-col text-lg font-medium">
            <Icons.cncLogo className="w-20 h-20 md:w-40" />
            <div className="mt-2 md:mt-4 text-3xl md:text-5xl font-bold">
              {t("global_title")}
            </div>
          </div>
          {/* <img src={robotHandImage} alt="Authentication" className="hidden md:block" /> */}
        </div>
        <div className="pt-8 md:pt-0 md:px-20 px-10">
          <div className="text-3xl font-bold">{t(errors[errorCode].header)}</div>
          <div className="mt-2 mb-6 text-muted-foreground text-sm">
            {t(errors[errorCode].description)}
          </div>
          <Link to="/">
            <Button className="flex justify-center items-center">
              {" "}
              <Icons.chevronLeft />{" "}
              <span className="mx-2 center">{t(errors[errorCode].button)}</span>{" "}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
