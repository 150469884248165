import { sheetVariants } from "@ui/sheet";
import { type VariantProps } from "class-variance-authority";
import { create } from "zustand";
interface DialogState {
  showDialog: boolean;

  content: JSX.Element | JSX.Element[] | null | string;
  callback?: () => void;
  triggerDialog: (showDialog: boolean) => void;
  side: VariantProps<typeof sheetVariants>["side"];
  show: ({
    content,
    side,
    callback,
  }: {
    content: JSX.Element | JSX.Element[] | null | string;
    side: VariantProps<typeof sheetVariants>["side"];
    callback?: () => void;
  }) => void;
}

export const useDialogStore = create<DialogState>()((set) => ({
  showDialog: false,
  side: "bottom",
  content: null,
  show: ({ content, side, callback }) =>
    set((state) => ({
      ...state,
      content: content,
      callback: callback,
      side: side,
      showDialog: true,
    })),
  triggerDialog: (showDialog) =>
    set(() => ({
      showDialog: showDialog,
    })),
}));
