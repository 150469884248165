import * as CommonAccess from "@access/common.access";
import {
  SearchReponse,
  TaxPayload,
  TaxResponse,
  UploadUrlPayload,
  UploadUrlResponse,
} from "@access/common.access";

export const getHomeInsights = async (): Promise<CommonAccess.HomeInsightsResponse> => {
  return CommonAccess.getHomeInsights();
};

export const updateMessageStatus = async (
  tasks: Pick<CommonAccess.AccountTask, "solved" | "taskId">[] = [],
): Promise<{
  data: string | null;
  status: number;
}> => {
  return CommonAccess.updateMessageStatus(tasks);
};

export const getMessages = async (): Promise<{
  data: {
    tasks: CommonAccess.AccountTask[];
    readTasks: CommonAccess.AccountTask[];
  } | null;
  status: number;
}> => {
  const { data, status } = await CommonAccess.getMessages();
  if (data?.tasks) {
    return {
      data: {
        tasks: data.tasks.filter((task) => !task.solved) || [],
        readTasks: data.tasks.filter((task) => task.solved) || [],
      },
      status,
    };
  }
  return {
    data: null,
    status: 404,
  };
};
export const searchForCompanyDetails = async (query?: string): Promise<SearchReponse> => {
  if (!query) return { query: "", suggestionCategory: "", companyNameSuggestions: [] };
  return CommonAccess.searchForCompanyDetails(query);
};

export const uploadUrl = async (
  payload: UploadUrlPayload,
): Promise<UploadUrlResponse> => {
  return CommonAccess.uploadUrl(payload);
};
export const uploadUrlLead = async (
  payload: UploadUrlPayload,
): Promise<UploadUrlResponse> => {
  return CommonAccess.uploadUrlLead(payload);
};

export const getCompanyTax = async (payload: TaxPayload): Promise<TaxResponse> => {
  return CommonAccess.getCompanyTax(payload);
};
