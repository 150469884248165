import { Icons } from "@components/icons";
import { queryClient } from "@config/query.config";
import { PAGES_ROUTES } from "@config/routes";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Separator } from "@ui/separator";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SuccessNewUserDialog = ({
  success,
}: {
  success: "qualified" | "not_qualified";
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reset } = useNewUserWizardStore((state) => state);
  return (
    <div className="h-full">
      <div className="max-w-3xl mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0 h-full">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="flex justify-center mb-4">
            <Icons.complaintIllustration className="size-40 md:size-60" />
          </div>
          <div className="space-y-6">
            <h1 className="text-2xl md:text-4xl font-bold text-center">
              {t("dashboard-yourcompanyprofileinformationhasbeensuccessfullysa")}
            </h1>
            <ul className="space-y-4 text-sm text-muted-foreground">
              {success === "qualified" ? (
                <>
                  <li className="flex gap-4 items-center">
                    <div>
                      <Icons.arrowRightIcon />
                    </div>
                    <p>{t("dashboard-qualified_p_1")}</p>
                  </li>
                  <li className="flex gap-4 items-center">
                    <div>
                      <Icons.arrowRightIcon />
                    </div>
                    <p>{t("dashboard-qualified_p_2")}</p>
                  </li>
                  <li className="flex gap-4 items-center">
                    <div>
                      <Icons.arrowRightIcon />
                    </div>
                    <p>{t("dashboard-qualified_p_3")}</p>
                  </li>{" "}
                </>
              ) : (
                <>
                  <li className="flex gap-4 items-center">
                    <div>
                      <Icons.arrowRightIcon />
                    </div>
                    <p>{t("dashboard-disqualified_p1")}</p>
                  </li>
                  <li className="flex gap-4 items-center">
                    <div>
                      <Icons.arrowRightIcon />
                    </div>
                    <p>{t("dashboard-disqualified_p2")}</p>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="w-full bg-background">
          <Separator className="w-full bg-muted h-[1px]" />
          <div className="flex w-full justify-between p-8">
            <Button
              onClick={() => {
                reset();
                queryClient.invalidateQueries({ queryKey: ["user"] });
                navigate(`${PAGES_ROUTES.PROFILE}?tab=company`);
              }}
              variant={"outline"}
              type="button"
            >
              {t("dashboard-gotoyouraccount")}
            </Button>
            <Button
              onClick={() => {
                reset();
                navigate(PAGES_ROUTES.MAIN);
              }}
            >
              {t("dashboard-gotoyourdashboard")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessNewUserDialog;
