import * as OfferService from "@services/offer.service";
import { useQuery } from "@tanstack/react-query";

export const useGetOfferDetailsId = (offerId?: string) => {
  return useQuery({
    queryKey: [offerId],
    queryFn: () => OfferService.getOfferDetails(offerId ? offerId : ""),
    enabled: !!offerId,
  });
};
