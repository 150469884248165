import {
  addMonths,
  endOfDay,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";

interface DateRange {
  from?: Date;
  to?: Date;
}

export const DAY_MONTH_YEAR_FORMAT = "yyyy-LL-dd";

export function isMatched(
  monthsOffset: number,
  dateRange: DateRange,
  currentDate: Date,
): boolean {
  if (!dateRange.from || !dateRange.to) {
    return false;
  }
  let startDate: Date;
  let endDate: Date;

  if (monthsOffset >= 0) {
    // If monthsOffset is positive, add months
    startDate = addMonths(new Date(), -monthsOffset);
    endDate = currentDate;
  } else if (monthsOffset === -11) {
    startDate = subYears(startOfYear(new Date()), 1);
    endDate = currentDate;
  } else {
    // If monthsOffset is negative, subtract months
    startDate = subMonths(new Date(), Math.abs(monthsOffset));
    endDate = currentDate;
  }
  const isSameStartDate =
    startOfDay(startDate).getTime() === startOfDay(dateRange.from).getTime();
  const isSameEndDate = endOfDay(endDate).getTime() === endOfDay(dateRange.to).getTime();

  return isSameStartDate && isSameEndDate;
}

export function isWithinRange(date: Date, { from, to }: { from: Date; to: Date }) {
  return (
    isEqual(date, from) ||
    (isAfter(date, from) && isBefore(date, to)) ||
    isEqual(date, to)
  );
}
