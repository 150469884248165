export const TRACKING_EVENTS = {
  SIGN_UP: "customer_portal_signup",
  LOGIN: "customer_portal_login",
  InviteTeamMembersCTAclick: "inviteTeamMembersCTAclick",
  teamMembersInvited: "teamMembersInvited",
  inquiryWizardStarted: "inquiryWizardStarted",
  inquiryOrderPartsCTAclick: "inquiryOrderPartsCTAclick",
  inquiryOrderPartsCancelledStatusCTAclick: "inquiryOrderPartsCancelledStatusCTAclick",
  inquiryReorderPartsCTAclick: "inquiryReorderPartsCTAclick",
  inquiryPartDrawingOpened: "inquiryPartDrawingOpened",
  inquiryDrawingsDownloaded: "inquiryDrawingsDownloaded",
  inquiryAcceptFrameworkAgreementCTAclick: "inquiryAcceptFrameworkAgreementCTAclick",
  inquiryOrderPartsFrameworkAgreementCTAclick: "inquiryOrderPartsFrameworkAgreementCTA",
  orderReorderPartsCTAclick: "orderReorderPartsCTAclick",
  orderPriceBreakdownExpandedLinkClick: "orderPriceBreakdownExpandedLinkClick",
  orderPartDrawingOpened: "orderPartDrawingOpened",
  orderDeliveriesPartsExpandedArrowclick: "orderDeliveriesPartsExpandedArrowclick",
  orderUpdateContactsCTAclick: "orderUpdateContactsCTAclick",
  frameworkOrderNextBatchCTAclick: "frameworkOrderNextBatchCTAclick",
  frameworkPriceBreakdownExpandedLinkClick: "frameworkPriceBreakdownExpandedLinkClick",
  frameworkBatchOrderPartsExpandedArrow: "frameworkBatchOrderPartsExpandedArrow",
  frameworkPartDrawingOpened: "frameworkPartDrawingOpened",
  ordered: "orderCustomerPortal",
  startOrderProccess: "startOrderProccess",
  sign_up_click_on_sign_up: "sign_up_click_on_sign_up",
  sign_up_form_click_on_login: "sign_up_form_click_on_login",
  sign_up_error_account_already_exists: "sign_up_error_account_already_exists",
  sign_up_error: "sign_up_error",
  sign_up_form_login_successfully: "sign_up_form_login_successfully",
  login_forgot_password: "login_forgot_password",
  login_click_login: "login_click_login",
  login_successfully: "login_successfully",
  login_error: "login_error",
  wizardSubmitted: "wizardSubmitted",
} as const;
