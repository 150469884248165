import useObjectTranslations from "@hooks/useObjectTranslations";
import { OfferDetailsResponse } from "@models/offer";
import { t } from "i18next";
// eslint-disable-next-line react-hooks/rules-of-hooks
const orderConditionsTranslationsObject = useObjectTranslations("summary-text-bullets");
const OrderConditions = ({ offerDetails }: { offerDetails: OfferDetailsResponse }) => {
  const { orderConditions } = offerDetails;
  if (orderConditions.length === 0) return <></>;
  return (
    <ul className="text-sm list-disc list-outside mt-2">
      {orderConditions.map((orderCondition, index) => {
        let value: string = orderConditionsTranslationsObject[orderCondition]
          ? orderConditionsTranslationsObject[orderCondition]
          : "";
        switch (orderCondition) {
          case "isNormalShippingCosts":
            {
              value =
                value.replace(
                  "{shippingCosts}",
                  (offerDetails?.shippingCosts?.toString() as string) || "0",
                ) + "€";
            }
            break;
          case "minimumOrderValue":
            value = orderConditionsTranslationsObject[orderCondition];
            break;
          case "capacityReservedUntil":
            value = value.replace(
              "{capacityReservedUntil}",
              offerDetails.capacityReservedUntil as string,
            );
            break;

          case "paymentTerms":
            value = value.replace(
              "{paymentTerms}",
              JSON.parse(t("paymentTerms"))[offerDetails.paymentTerms as string],
            );
            value = value.replace(
              "{improvedPaymentTerms}",
              JSON.parse(t("improvedPaymentTerms"))[
                offerDetails.improvedPaymentTerms as string
              ],
            );
            break;

          case "paymentTermsDiscount":
            value = value.replace(
              "{paymentTerms}",
              JSON.parse(t("paymentTerms"))[offerDetails.paymentTerms as string],
            );
            break;
          default:
            value;
        }

        return (
          <li
            className="link-conditions"
            key={index}
            dangerouslySetInnerHTML={{ __html: value }}
          ></li>
        );
      })}
    </ul>
  );
};

export default OrderConditions;
