import { Icons } from "@components/icons";
import NewUserDialogForm from "@components/new-user-dialog/new-user-dialog.form";
import SuccessNewUserDialog from "@components/new-user-dialog/steps/success";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Dialog, DialogContent } from "@ui/dialog";
import { t } from "i18next";
import { useState } from "react";

const NewUserDialog = () => {
  const [open, setOpen] = useState(true);
  const { success, reset } = useNewUserWizardStore((state) => state);
  return (
    <Dialog
      open={open}
      onOpenChange={(change) => {
        reset();
        setOpen(change);
      }}
    >
      <DialogContent className="relative sm:max-w-[90rem] md:max-lg:max-w-[64rem] max-w-2xl h-screen overflow-y-scroll md:h-[90vh] flex flex-col p-0">
        <>
          {!success ? (
            <>
              <div className="text-center">
                <div className="space-y-4 flex flex-col justify-center px-8 pt-8">
                  <div className="flex justify-center">
                    <Icons.dashboardIllustration className="w-48" />
                  </div>
                  <h1 className="font-display font-bold sm:text-3xl text-2xl">
                    {t("dashboard-complete_company_profile")}
                  </h1>
                  <div className="hidden sm:block text-muted-foreground">
                    <p>{t("dashboard-complete_company_profile_desc")}</p>
                    <p>{t("dashboard-complete_company_profile_desc_2")}</p>
                  </div>
                </div>
              </div>
              <div>
                <NewUserDialogForm />
              </div>
            </>
          ) : (
            <SuccessNewUserDialog success={success} />
          )}
        </>
      </DialogContent>
    </Dialog>
  );
};

export default NewUserDialog;
