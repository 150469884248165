import "@src/styles/global.css";

import { AppContainer } from "@components/app-container.tsx";
import i18n from "@config/i18n";
import { PAGES_ROUTES } from "@config/routes";
import { lazy, Suspense, useEffect } from "react";
const DashboardPage = lazy(() => import("@pages/dashboard"));
import ErrorPage from "@pages/errorPage.tsx";
const FrameworkAgreementsPage = lazy(() => import("@pages/framerwork-agreements"));
const FrameworkAgreementID = lazy(() => import("@pages/framework-agreement-id"));
const InqueryPage = lazy(() => import("@pages/inqueries"));
const Login = lazy(() => import("@pages/login.tsx"));
const NewFrameworkAgreementPage = lazy(() => import("@pages/new-framework-agreement"));
// const NewInqury = lazy(() => import("@pages/new-inqury"));
const NewOrderPage = lazy(() => import("@pages/new-order"));
const NewOfferPage = lazy(() => import("@pages/new-offer"));
const NewUserSignUp = lazy(() => import("@pages/new-user-sign-up"));
const OrderId = lazy(() => import("@pages/order-id"));
const OrderNewFramerworkAgreement = lazy(
  () => import("@pages/order-new-framework-agreement"),
);
const OrderPlacedSuccessPage = lazy(() => import("@pages/order-success"));
const OrdersPage = lazy(() => import("@pages/orders"));
const Profile = lazy(() => import("@pages/profile"));
const SignUpPage = lazy(() => import("@pages/sign-up"));
const Verification = lazy(() => import("@pages/verification"));
const VerificationSuccess = lazy(() => import("@pages/verification-success"));
import NewInquiryPage from "@pages/create-new-inquiry";
import InqueryIdNew from "@pages/inq-id-new";
import { Loader } from "@ui/loader";
import { getLanguageFromBrowser } from "@utils/getLanguage";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";

const Router = () => {
  const location = useLocation();
  const [params] = useSearchParams();

  const language = getLanguageFromBrowser(params);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(params.get("lang") || i18n.language || language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.documentElement.classList.add("safari");
    }
  }, [location]);
  return (
    <>
      <Suspense
        fallback={
          <Loader
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            size="xl"
            className="fixed inset-0 z-50 w-full min-h-screen flex justify-center items-center bg-background"
          />
        }
      >
        <Routes location={location}>
          <Route path={PAGES_ROUTES.MAIN} element={<AppContainer />}>
            <Route index element={<DashboardPage />} />
            <Route path={PAGES_ROUTES.ORDERS} element={<OrdersPage />} />
            <Route path={PAGES_ROUTES.OFFERS} element={<InqueryPage />} />
            <Route path={PAGES_ROUTES.OFFERS_ID} element={<InqueryIdNew />} />
            <Route path={PAGES_ROUTES.OFFERS_ID_ORDER_PARTS} element={<NewOrderPage />} />
            <Route
              path={PAGES_ROUTES.OFFERS_ID_FRAMEWORK_AGREEMENT}
              element={<NewFrameworkAgreementPage />}
            />
            <Route
              path={PAGES_ROUTES.ORDER_FRAMEWORK_AGREEMENT}
              element={<FrameworkAgreementID />}
            />
            <Route
              path={PAGES_ROUTES.ORDER_ID_NEW_FRAMEWORK_AGREEMENT}
              element={<OrderNewFramerworkAgreement />}
            />
            <Route
              path={PAGES_ROUTES.OFFERS_SUCCESS}
              element={<OrderPlacedSuccessPage />}
            />
            <Route path={PAGES_ROUTES.ORDER} element={<OrderId />} />
            <Route
              path={PAGES_ROUTES.FRAMEWORK_AGREEMENTS}
              element={<FrameworkAgreementsPage />}
            />
            <Route path={PAGES_ROUTES.PROFILE} element={<Profile />} />
            <Route path={PAGES_ROUTES.NEW_INQURY} element={<NewInquiryPage />} />
          </Route>
          <Route path={PAGES_ROUTES.OFFERS_NEW} element={<NewOfferPage />} />
          {/* <Route path={PAGES_ROUTES.OFFERS_NEW} element={<NewInqury />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/sign-up-invite" element={<NewUserSignUp />} />
          <Route path="/verify-email-expired" element={<Verification />} />
          <Route path="/verify-email-success" element={<VerificationSuccess />} />
          <Route path="404" element={<ErrorPage errorCode={404} />} />
          <Route path="403" element={<ErrorPage errorCode={403} />} />
          <Route path="*" element={<ErrorPage errorCode={404} />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
