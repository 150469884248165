import * as OfferAccess from "@access/offer.access";
import {
  AcceptOfferData,
  Offer,
  OfferDetailsResponse,
  OfferUpsertPayload,
  UpsertOfferPayload,
} from "@models/offer";

export async function getOffers({
  params,
}: {
  params?: OfferAccess.OfferParams;
}): Promise<{
  data: Offer[] | null;
  error?: string;
}> {
  return OfferAccess.getOffers({ params });
}
export async function getLeadInProgress(recordId: string): Promise<{
  data: Offer[] | null;
  error?: string;
}> {
  return OfferAccess.getLeadInProgress(recordId);
}

export async function getOfferDetails(offerId: string): Promise<{
  data: OfferDetailsResponse | null;
  error?: string;
}> {
  return OfferAccess.getOfferDetails(offerId);
}

export async function acceptOfferProcess({
  payload,
}: {
  payload: OfferAccess.AcceptOfferPayload;
}): Promise<{
  data: AcceptOfferData | null;
  error?: string;
}> {
  return OfferAccess.acceptOfferProcess({ payload });
}
export async function upsertOffer({ payload }: { payload: OfferUpsertPayload }): Promise<{
  data: UpsertOfferPayload | null;
  error?: string;
}> {
  return OfferAccess.upsertOffer({ payload });
}
