import { CUSTOMER_API_ROUTES } from "@config/api";
import { postWithToken } from "@lib/request";

export interface AccountDetails {
  industry?: string | null;
  offerTime?: string | null;
  leadTimePartDelivery?: string | null;
  otherProductionMethod?: string | string[] | null;
  other_link?: string | null;
  additionalCertifications?: string | null;
  productionMethods?: string[] | null;
  social_links?: string[] | null;
  documentsRequired?: string[] | null;
  certificationsRequired?: string[] | null;
  preferredSourcingRegions?: string[] | null;
  currentSourcingRegions?: string[] | null;
  numberOfSuppliers?: number | null;
  numberOfPurchasers?: number | null;
  employees?: number | null;
  annualPurchasingVolume?: number;
  typeOfCollaboration?: "Occasional order" | "Ongoing collaboration" | null;
  inhouseProduction?: "Yes" | "No" | null;
}

export interface CompanyProfile {
  address?: {
    street?: string;
    postalCode?: string;
    city?: string;
    countryCode?: string;
    phone?: string;
    vat?: string;
  };
  sameShippingAddressAsCompanyAddress?: boolean;
  shippingAddress?: {
    street?: string;
    postalCode?: string;
    city?: string;
    countryCode?: string;
  };
  companyDetails?: AccountDetails;
  requirements: {
    additionalCertifications?: string[] | null;
    certificationsRequired?: string[] | null;
    documentsRequired?: string[] | null;
  };
}
export interface CompanyProfileResponse {
  data: string | null;
  status: number;
}

export async function updateCompanyProfile(
  payload: CompanyProfile,
): Promise<{ data: string | undefined; status: number }> {
  const { data, status } = await postWithToken<{
    data: string | undefined;
    status: number;
  }>(CUSTOMER_API_ROUTES.UPDATE_COMPANY_PROFILE, undefined, {
    ...payload,
  });
  if (data) {
    return { data: data, status: status };
  }
  return { data: undefined, status: 500 };
}
