import { CUSTOMER_API_ROUTES, SEARCH_URL } from "@config/api";
import { fetcher, fetcherWithToken, patchWithToken, put } from "@lib/request";
import { removeDiacritics } from "@utils/diacriticsMap";

export interface HomeInsightsResponse {
  data: {
    complaints: number;
    completedOrders: number;
    frameworks: number;
    inquiries: number;
    nrOfParts: number;
    nrOfPartsOrdered: number;
    openInquiries: number;
    orders: number;
    runnigOrders: number;
    runningFrameworks: 170;
    totalBookings: number;
    totalRevenue: number;
  } | null;
  status: number;
}

export type MessageType = "Order open" | "Order completed" | "Inquiry" | "Order shipped";

export interface AccountTask {
  type: MessageType;
  taskId: string;
  solved: boolean;
  recordId: string;
  itemNumber: string;
  customerOrderRef: string;
  createdDate: string;
}

export interface MessagesResponse {
  data: {
    tasks: AccountTask[];
  } | null;
  status: number;
}
export interface CompanyNameSuggestion {
  refonummer: string;
  identnummer: string;
  crefonummer: string;
  name: string;
  land: string;
  bundesland: string;
  plz: string;
  ort: string;
  strasseHausnummer: string;
}
export interface SearchReponse {
  query: string;
  suggestionCategory: string;
  companyNameSuggestions: CompanyNameSuggestion[];
}

export const getHomeInsights = async (): Promise<HomeInsightsResponse> => {
  return await fetcherWithToken<HomeInsightsResponse>(CUSTOMER_API_ROUTES.HOME_INSIGHT);
};

export const getMessages = async (): Promise<MessagesResponse> => {
  return await fetcherWithToken<MessagesResponse>(CUSTOMER_API_ROUTES.MESSAGES);
};

export const updateMessageStatus = async (
  tasks: Pick<AccountTask, "solved" | "taskId">[] = [],
): Promise<{
  data: string | null;
  status: number;
}> => {
  return await patchWithToken(CUSTOMER_API_ROUTES.UPDATE_MESSAGE_STATUS, {
    tasks,
  });
};

export const searchForCompanyDetails = async (query: string): Promise<SearchReponse> => {
  return await fetcher<SearchReponse>(SEARCH_URL.SEARCH(query + "*"));
};
export interface TaxPayload {
  taxId?: string;
  country: string;
}
export interface TaxResponse {
  data: {
    taxPercent: number;
    reverseCharged: boolean;
  } | null;
  status: number;
}

export const getCompanyTax = async ({
  taxId,
  country,
}: TaxPayload): Promise<TaxResponse> => {
  const url = new URL(CUSTOMER_API_ROUTES.TAX);
  url.searchParams.append("country", country);
  if (taxId) {
    url.searchParams.append("taxId", taxId);
  }
  return await fetcherWithToken<TaxResponse>(url.toString());
};

export interface UploadUrlResponse {
  data: {
    fileId: string;
    fileName: string;
  } | null;
  status: number;
}
export interface UploadUrlToCPResponse {
  data: {
    uuid: string;
    url: string;
    action: null;
  };
  status: number;
}
export interface UploadUrlPayload {
  fileName: string;
  recordId?: string;
  file: File;
}
export const uploadUrl = async ({
  file,
  fileName,
  recordId,
}: UploadUrlPayload): Promise<UploadUrlResponse> => {
  try {
    const {
      data: { url, uuid },
    } = await patchWithToken<UploadUrlToCPResponse>(CUSTOMER_API_ROUTES.UPLOAD_URL, {
      fileName,
      recordId,
    });
    console.log("🚀 ~ uuid:", uuid);
    console.log("🚀 ~ url:", url);
    await put(url, file, {
      "Content-Type": file.type,
      "Content-Disposition": `attachment; filename="${removeDiacritics(file.name)}"`,
    });
    return {
      data: {
        fileId: uuid,
        fileName,
      },
      status: 200,
    };
  } catch (error) {
    return {
      data: null,
      status: 500,
    };
  }
};

export const uploadUrlLead = async ({
  file,
  fileName,
  recordId,
}: UploadUrlPayload): Promise<UploadUrlResponse> => {
  try {
    const {
      data: { url, uuid },
    } = await patchWithToken<UploadUrlToCPResponse>(CUSTOMER_API_ROUTES.UPLOAD_URL_LEAD, {
      fileName,
      recordId,
    });
    await put(url, file, {
      "Content-Type": file.type,
      "Content-Disposition": `attachment; filename="${removeDiacritics(file.name)}"`,
    });
    return {
      data: {
        fileId: uuid,
        fileName,
      },
      status: 200,
    };
  } catch (error) {
    return {
      data: null,
      status: 500,
    };
  }
};
