import { zodResolver } from "@hookform/resolvers/zod";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Checkbox } from "@ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as z from "zod";

const accountDetailsWizard = z.object({
  certificationsRequired: z.array(z.string()).optional(),
  additionalCertifications: z.string().optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const CertificatesForm = () => {
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    certificationsRequired: accountDetails?.certificationsRequired || undefined,
    additionalCertifications: accountDetails?.additionalCertifications || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });

  async function onSubmit({
    certificationsRequired,
    additionalCertifications,
  }: z.infer<typeof accountDetailsWizard>) {
    setWizardDetails("certificationsRequired", certificationsRequired);
    setWizardDetails("additionalCertifications", additionalCertifications);
    setStepStatus("6", true);
    setCurrentStep("7");
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[20vh] sm:min-h-[34vh] mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0">
            <div className="text-destructive text-xs mb-6">
              {" "}
              * {t("dashboard-any_info_your_not_sure")}
            </div>
            <div className="space-y-10">
              <FormField
                control={form.control}
                name="certificationsRequired"
                render={() => (
                  <FormItem>
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-are_there_special_certificates_required_from_suppl")}
                    </FormLabel>
                    <div className="flex md:flex-row flex-col gap-6 md:gap-20 pt-4">
                      {[
                        "ISO 9001",
                        "ISO 13485",
                        "ISO 14001",
                        "IATF 16949",
                        "AS 9001D",
                      ].map((value) => (
                        <FormField
                          key={value}
                          control={form.control}
                          name="certificationsRequired"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={value}
                                className="flex flex-row items-center space-x-3 space-y-0"
                              >
                                <FormControl>
                                  <Checkbox
                                    className="h-5 w-5"
                                    checked={field.value?.includes(value)}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([...(field?.value || []), value])
                                        : field.onChange(
                                            field.value?.filter(
                                              (value) => value !== value,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="text-sm font-normal">
                                  {value}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="additionalCertifications"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-would_you_provide_more_certificates")}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="!mt-0 max-w-xl"
                        placeholder={
                          t("dashboard-please_specify_other_certificates") as string
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-between p-8">
              <Button
                onClick={() => setCurrentStep("5")}
                variant={"outline"}
                type="button"
              >
                {t("global_go-back-btn")}
              </Button>
              <Button>{t("global_continue-btn")}</Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CertificatesForm;
