import i18n from "@config/i18n";
import { cn } from "@lib/utils";
import { buttonVariants } from "@ui/button";
import { format, isEqual } from "date-fns";
import { de, enGB } from "date-fns/locale";
import { ChevronLeft, ChevronRight } from "lucide-react";
import * as React from "react";
import { DayContent, DayContentProps, DayPicker, Matcher } from "react-day-picker";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  isDayHighlighted?: (date: Date) => boolean;
};
function DateTime(
  props: DayContentProps & {
    isDayHighlighted: (date: Date) => boolean;
    selectedDate: Matcher[] | Matcher | undefined;
  },
) {
  const dateTime = format(props.date, "yyyy-MM-dd");
  return (
    <time
      dateTime={dateTime}
      className={cn(
        props.isDayHighlighted(props.date) &&
          "bg-brand transition-all flex justify-center items-center text-white h-8 w-8 rounded-full",
        props.selectedDate && isEqual(props.date, props.selectedDate as Date)
          ? "bg-inherit text-inherit"
          : "",
      )}
    >
      <DayContent {...props} />
    </time>
  );
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  isDayHighlighted = () => false,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
        ),
        day_selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside: "text-muted-foreground opacity-50",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4" />,
        IconRight: () => <ChevronRight className="h-4 w-4" />,
        DayContent: (dayContentProps: DayContentProps) => (
          <DateTime
            {...dayContentProps}
            isDayHighlighted={isDayHighlighted}
            selectedDate={props.selected}
          />
        ),
      }}
      locale={i18n.language === "de" ? de : enGB}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
