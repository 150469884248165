import { zodResolver } from "@hookform/resolvers/zod";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import { RadioGroup, RadioGroupItem } from "@ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/select";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as z from "zod";

// eslint-disable-next-line react-hooks/rules-of-hooks
const industryList = useObjectTranslations("industry_list");

const accountDetailsWizard = z.object({
  employees: z.number().optional(),
  industry: z.string().optional(),
  inhouseProduction: z.enum(["Yes", "No"]).optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const CompanyForm = () => {
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    industry: accountDetails.industry || undefined,
    employees: accountDetails.employees || undefined,
    inhouseProduction: accountDetails.inhouseProduction || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });

  async function onSubmit({
    industry,
    employees,
    inhouseProduction,
  }: z.infer<typeof accountDetailsWizard>) {
    setWizardDetails("industry", industry);
    setWizardDetails("inhouseProduction", inhouseProduction);
    setWizardDetails("employees", employees);
    setStepStatus("1", true);
    setCurrentStep("2");
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[10vh] sm:min-h-[38vh] mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0">
            <div className="text-destructive text-xs mb-6">
              * {t("dashboard-any_info_your_not_sure")}
            </div>
            <div className="space-y-10">
              <FormField
                control={form.control}
                name="employees"
                render={({ field }) => (
                  <FormItem className="space-y-3 !mt-6">
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-how_many_employees_you_have")}
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) => field.onChange(parseInt(e.target.value))}
                        className="max-w-lg"
                        placeholder={
                          t("dashboard-please_specify_number_of_employees") as string
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="industry"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormLabel className="font-bold font-display sm:text-lg text-sm">
                          {t("dashboard-which_industry_business_operates")}{" "}
                        </FormLabel>
                        <SelectTrigger className="max-w-lg">
                          <SelectValue
                            placeholder={
                              <span className="text-muted-foreground">
                                {t("dashboard-select_your_industry")}
                              </span>
                            }
                          />
                        </SelectTrigger>
                        <SelectContent className="max-h-96">
                          {Object.entries(industryList).map(([code, name]) => {
                            return (
                              <SelectItem key={code} value={code}>
                                {name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="inhouseProduction"
                render={({ field }) => (
                  <FormItem className="space-y-3 !mt-6">
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-is_production_handled_inhouse")}
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex gap-12"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={"Yes"} />
                          </FormControl>
                          <FormLabel className="font-normal text-sm">
                            {t("global_yes")}
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={"No"} />
                          </FormControl>
                          <FormLabel className="font-normal text-sm">
                            {t("global_no")}
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-end p-8">
              <Button>{t("global_continue-btn")}</Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CompanyForm;
