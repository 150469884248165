import { zodResolver } from "@hookform/resolvers/zod";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { RadioGroup, RadioGroupItem } from "@ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/select";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as z from "zod";

const accountDetailsWizard = z.object({
  typeOfCollaboration: z.enum(["Occasional order", "Ongoing collaboration"]).optional(),
  leadTimePartDelivery: z.string().optional(),
  offerTime: z.string().optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const InquiriesForm = () => {
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    typeOfCollaboration: accountDetails.typeOfCollaboration || undefined,
    offerTime: accountDetails.offerTime || undefined,
    leadTimePartDelivery: accountDetails.leadTimePartDelivery || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });

  async function onSubmit({
    offerTime,
    leadTimePartDelivery,
    typeOfCollaboration,
  }: z.infer<typeof accountDetailsWizard>) {
    setWizardDetails("offerTime", offerTime);
    setWizardDetails("leadTimePartDelivery", leadTimePartDelivery);
    setWizardDetails("typeOfCollaboration", typeOfCollaboration);
    setStepStatus("5", true);
    setCurrentStep("6");
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[20vh] sm:min-h-[38vh] mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0">
            <div className="text-destructive text-xs mb-6">
              {" "}
              * {t("dashboard-any_info_your_not_sure")}
            </div>
            <div className="space-y-10">
              <FormField
                control={form.control}
                name="offerTime"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormLabel className="font-bold font-display sm:text-lg text-sm">
                          {t("dashboard-how_soon_you_inquiry")}
                        </FormLabel>
                        <SelectTrigger className="max-w-lg">
                          <SelectValue
                            placeholder={
                              <span className="text-muted-foreground">
                                {t("dashboard-please_specify_no_of_days")}
                              </span>
                            }
                          />
                        </SelectTrigger>
                        <SelectContent className="max-h-96">
                          {["1", "2", "3", "4", "5", "7"].map((number) => {
                            return (
                              <SelectItem key={number} value={number}>
                                {number} {t("global_days")}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <div className="text-muted-foreground text-sm !pt-1">
                      {t("dashboard-specify_more_days_favouriable_prices")}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="leadTimePartDelivery"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormLabel className="font-bold font-display sm:text-lg text-sm">
                          {t("dashboard-whats_your_typical_lead_time_delivery")}
                        </FormLabel>
                        <SelectTrigger className="max-w-lg">
                          <SelectValue
                            placeholder={
                              <span className="text-muted-foreground">
                                {t("dashboard-please_specify_no_of_days")}
                              </span>
                            }
                          />
                        </SelectTrigger>
                        <SelectContent className="max-h-96">
                          {["20", "25", "30", "35", "40", "45"].map((number) => {
                            return (
                              <SelectItem key={number} value={number}>
                                {number} {t("global_days")}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="typeOfCollaboration"
                render={({ field }) => (
                  <FormItem className="space-y-3 !mt-6">
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-are_you_looking_for_active_colab")}
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-wrap gap-10"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Occasional order" />
                          </FormControl>
                          <FormLabel className="font-normal text-sm">
                            {t("dashboard-occasional_orders")}
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Ongoing collaboration" />
                          </FormControl>
                          <FormLabel className="font-normal text-sm">
                            {t("dashboard-ongoingcollaboration")}
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-between p-8">
              <Button
                onClick={() => setCurrentStep("4")}
                variant={"outline"}
                type="button"
              >
                {t("global_go-back-btn")}
              </Button>
              <Button>{t("global_continue-btn")}</Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default InquiriesForm;
