import { zodResolver } from "@hookform/resolvers/zod";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Checkbox } from "@ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as z from "zod";

// eslint-disable-next-line react-hooks/rules-of-hooks
const manufacturingProcessList = useObjectTranslations("manufacturing_proccess");

const accountDetailsWizard = z.object({
  productionMethods: z.array(z.string()).optional(),
  otherProductionMethod: z.string().optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const ManufacturingForm = () => {
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    productionMethods: accountDetails?.productionMethods || undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    otherProductionMethod: accountDetails?.otherProductionMethod || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });

  async function onSubmit({
    productionMethods,
    otherProductionMethod,
  }: z.infer<typeof accountDetailsWizard>) {
    setWizardDetails("productionMethods", productionMethods);
    setWizardDetails("otherProductionMethod", otherProductionMethod);
    setStepStatus("3", true);
    setCurrentStep("4");
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[20vh] sm:min-h-[34vh] mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0">
            <div className="text-destructive text-xs mb-6">
              {" "}
              * {t("dashboard-any_info_your_not_sure")}
            </div>
            <div className="space-y-10">
              <FormField
                control={form.control}
                name="productionMethods"
                render={() => (
                  <FormItem>
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-what_manufacturing_proccess_you_use")}
                    </FormLabel>
                    <div className="flex md:flex-row flex-col gap-4 md:gap-20 pt-4">
                      {Object.entries(manufacturingProcessList).map(([code, name]) => (
                        <FormField
                          key={code}
                          control={form.control}
                          name="productionMethods"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={code}
                                className="flex flex-row items-center space-x-3 space-y-0"
                              >
                                <FormControl>
                                  <Checkbox
                                    className="h-5 w-5"
                                    checked={field.value?.includes(code)}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([...(field?.value || []), code])
                                        : field.onChange(
                                            field.value?.filter(
                                              (value) => value !== code,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="text-sm font-normal">
                                  {name}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="otherProductionMethod"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-2">
                    <FormLabel>{t("global_other")}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="!mt-0 max-w-xl"
                        placeholder={
                          t(
                            "dashboard-pleasespecifyothermanufacturingprocesses",
                          ) as string
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-between p-8">
              <Button
                onClick={() => setCurrentStep("2")}
                variant={"outline"}
                type="button"
              >
                {t("global_go-back-btn")}
              </Button>
              <Button>{t("global_continue-btn")}</Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default ManufacturingForm;
