import { Icons } from "@components/icons";
import { queryClient } from "@config/query.config";
import { zodResolver } from "@hookform/resolvers/zod";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import * as Sentry from "@sentry/react";
import * as CompanyService from "@services/company.service";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Checkbox } from "@ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import { t } from "i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

// eslint-disable-next-line react-hooks/rules-of-hooks
const socialList = useObjectTranslations("social_links");

const accountDetailsWizard = z.object({
  social_links: z.array(z.string()).optional(),
  other_link: z.string().optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const SourceForm = () => {
  const [dataState, setDataState] = useState<"idle" | "progress" | "failed">("idle");
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails, setSuccess } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    social_links: accountDetails?.social_links || undefined,
    other_link: accountDetails?.other_link || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });

  async function onSubmit({
    social_links,
    other_link,
  }: z.infer<typeof accountDetailsWizard>) {
    try {
      setDataState("progress");
      setWizardDetails("social_links", social_links);
      setWizardDetails("other_link", other_link);
      setStepStatus("8", true);
      // TODO: Make a call to the server to store the data
      await CompanyService.updateCompanyProfile({
        companyDetails: {
          ...accountDetails,
          otherProductionMethod:
            typeof accountDetails.otherProductionMethod === "string"
              ? [accountDetails.otherProductionMethod]
              : accountDetails.otherProductionMethod,
          additionalCertifications: undefined,
          certificationsRequired: undefined,
          documentsRequired: undefined,
        },
        address: undefined,
        shippingAddress: undefined,
        requirements: {
          certificationsRequired: accountDetails.certificationsRequired,
          additionalCertifications: accountDetails.additionalCertifications
            ? [accountDetails.additionalCertifications]
            : undefined,
          documentsRequired: accountDetails.documentsRequired,
        },
      });
      await queryClient.invalidateQueries({ queryKey: ["user"] });
      if (
        accountDetails.annualPurchasingVolume &&
        accountDetails.annualPurchasingVolume >= 1000000
      ) {
        setSuccess("not_qualified");
      } else {
        setSuccess("qualified");
      }
    } catch (error) {
      Sentry.withScope(function (scope) {
        scope.setLevel("error");
        scope.setTag("service", "CompanyService.updateCompanyProfile");
        Sentry.captureException(error);
      });
      setDataState("failed");
    } finally {
      setDataState("idle");
    }
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[20vh] sm:min-h-[34vh] mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0">
            <div className="text-destructive text-xs mb-6">
              {" "}
              * {t("dashboard-any_info_your_not_sure")}
            </div>
            <div className="space-y-10">
              <FormField
                control={form.control}
                name="social_links"
                render={() => (
                  <FormItem>
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-howdidyouhearaboutcnc24")}
                    </FormLabel>
                    <div className="flex md:flex-row flex-col gap-4 md:gap-20 pt-4">
                      {Object.entries(socialList).map(([code, name]) => (
                        <FormField
                          key={code}
                          control={form.control}
                          name="social_links"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={code}
                                className="flex flex-row items-center space-x-3 space-y-0"
                              >
                                <FormControl>
                                  <Checkbox
                                    className="h-5 w-5"
                                    checked={field.value?.includes(code)}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([...(field?.value || []), code])
                                        : field.onChange(
                                            field.value?.filter(
                                              (value) => value !== code,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="text-sm font-normal">
                                  {name}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="other_link"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-2">
                    <FormLabel>{t("global_other")}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="!mt-0 max-w-xl"
                        placeholder={
                          t(
                            "dashboard-pleasespecifyothermanufacturingprocesses",
                          ) as string
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-between p-8">
              <Button
                onClick={() => setCurrentStep("7")}
                variant={"outline"}
                type="button"
              >
                {t("global_go-back-btn")}
              </Button>
              <Button>
                {dataState === "progress" && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}
                {t("global_submit")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default SourceForm;
