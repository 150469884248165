import { TaxPayload } from "@access/common.access";
import * as CommonService from "@services/common.service";
import { useQuery } from "@tanstack/react-query";

export const useGetTaxByCountry = (payload: TaxPayload) => {
  return useQuery({
    queryKey: ["tax", payload.country],
    queryFn: () => CommonService.getCompanyTax(payload),
  });
};
