import { Cookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";

const domainUrl = (env: "development" | "staging" | "production") => {
  switch (env) {
    case "development":
      return "localhost";
    case "staging":
      return ".cnc24.com";
    case "production":
      return ".cnc24.com";
    default:
      return ".cnc24.com";
  }
};

export const CookieStorage = new Cookies();

export const setCookie = (
  key: string,
  value: string,
  options?: CookieSetOptions | undefined,
) => {
  CookieStorage.set(key, value, {
    domain: domainUrl(import.meta.env.VITE_BUILD_TYPE),
    ...options,
  });
};
export const getCookie = (key: string) => {
  return CookieStorage.get(key);
};
export const removeCookie = (key: string) => {
  CookieStorage.remove(key, {
    domain: domainUrl(import.meta.env.VITE_BUILD_TYPE),
  });
};
