import { Icons } from "@components/icons";
import Confirmation from "@components/new-order/steps/confirmation";
import OrderDetailsForm from "@components/new-order/steps/order-details.form";
import Summary from "@components/new-order/steps/summary";
import { PAGES_ROUTES } from "@config/routes";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Steps, useInquiry } from "@store/wizard-inqury";
import { Badge } from "@ui/badge";
import { Button } from "@ui/button";
import { Progress } from "@ui/progress";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@ui/tabs";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

const NewInquiryPage = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const { offerId } = useParams();
  const navigate = useNavigate();
  const {
    wizzardState: { currentStep, stepsStatuses },
    setCurrentStep,
    reset,
  } = useInquiry((state) => state);

  return (
    <>
      <Tabs
        className="!border-none"
        value={currentStep}
        onValueChange={(e) => setCurrentStep(e as Steps)}
      >
        <div className="py-6 border-t bg-background w-full z-50">
          <div className="flex items-center justify-between mb-8">
            <h1 className="font-bold md:text-4xl text-2xl">
              {t("dashboard-ordercheckout")}
            </h1>
            <TabsList className="grid grid-cols-3 max-w-3xl m-auto transition-all h-12 my-8 bg-background border-none">
              <TabsTrigger
                value="1"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
              >
                <span className="md:flex hidden">
                  {t("wizard-header_step-order-details")}
                </span>
                <span className="ml-4">
                  {stepsStatuses[1] ? (
                    <Icons.checkBlue className="size-6" />
                  ) : (
                    <Badge variant={"brand"} className="leading-loose size-7">
                      1
                    </Badge>
                  )}
                </span>
                <div className="md:ml-2 ml-1">
                  <Icons.chevronRight className=" size-6" />
                </div>
              </TabsTrigger>
              <TabsTrigger
                value="2"
                disabled={!stepsStatuses["1"]}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
              >
                <span className="md:flex hidden">{t("wizard-header_step-summary")}</span>
                <span className="ml-4">
                  {stepsStatuses[2] ? (
                    <Icons.checkBlue className="size-6" />
                  ) : (
                    <Badge
                      variant={
                        stepsStatuses[2] || currentStep === "2" ? "brand" : "outline"
                      }
                      className="leading-loose size-7"
                    >
                      2
                    </Badge>
                  )}
                </span>
                <div className="md:ml-2 ml-1">
                  <Icons.chevronRight className="ml-2 size-6" />
                </div>
              </TabsTrigger>
              <TabsTrigger
                disabled={!stepsStatuses["2"]}
                value="3"
                className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="md:flex hidden">{t("dashboard-confirmation")}</span>
                <span className="ml-4">
                  {stepsStatuses[3] ? (
                    <Icons.checkBlue className="size-6" />
                  ) : (
                    <Badge
                      variant={
                        stepsStatuses[3] || currentStep === "3" ? "brand" : "outline"
                      }
                      className="leading-loose size-7"
                    >
                      3
                    </Badge>
                  )}
                </span>
              </TabsTrigger>
            </TabsList>
            <div className="flex">
              <div className="flex items-center gap-4">
                <Link to="https://www.cnc24.com/contact/" target="_blank">
                  <Button variant={"link"} className="text-muted-foreground group">
                    <Icons.questionMarkFat className="size-5 fill-[#838A9C] group-hover:fill-brand " />
                    <div className="text-sm font-bold ml-2 capitalize">
                      {t("dashboard-need_help")}
                    </div>
                  </Button>
                </Link>
                <Button
                  onClick={() => {
                    reset();
                    navigate(PAGES_ROUTES.OFFERS_ID_DETAILS(offerId as string));
                  }}
                  variant={"secondaryoutline"}
                >
                  {t("global_cancel-btn")}
                </Button>
              </div>
            </div>
          </div>

          <Progress
            value={currentStep === "1" ? 33 : currentStep === "2" ? 66 : 100}
            className="my-2 h-1"
          />
        </div>
        <TabsContent value="1">
          <OrderDetailsForm />
        </TabsContent>
        <TabsContent value="2">
          <Summary />
        </TabsContent>
        <TabsContent value="3">
          <Confirmation />
        </TabsContent>
      </Tabs>
    </>
  );
};

export default NewInquiryPage;
