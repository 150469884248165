import { Card, CardContent } from "@ui/card";
import { Skeleton } from "@ui/skeleton";

const InquiryLoader = () => {
  return (
    <div className="space-y-10">
      <div className="flex justify-between">
        <Skeleton className="h-10 w-28" />
        <Skeleton className="h-10 w-28" />
      </div>
      <div className="grid lg:grid-cols-3 sm:gap-8 gap-4 grid-cols-2">
        <Card className="flex flex-col space-y-3 lg:col-span-1 col-span-2 border-none shadow-2xl">
          <CardContent className="p-6">
            <Skeleton className="h-[125px] w-full rounded-xl" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
            </div>
          </CardContent>
        </Card>
        <Card className="col-span-2 font-bold font-display border-none shadow-2xl">
          <CardContent className="grid md:grid-cols-3 grid-cols-1 sm:gap-4 gap-2 sm:p-8 p-4">
            <Card className="h-36 flex items-center">
              <CardContent className="sm:p-6 p-3 w-full">
                <Skeleton className="h-[70px] w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </CardContent>
            </Card>
            <Card className="h-36 flex items-center">
              <CardContent className="sm:p-6 p-3 w-full">
                <Skeleton className="h-[70px] w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </CardContent>
            </Card>
            <Card className="h-36 flex items-center">
              <CardContent className="sm:p-6 p-3 w-full">
                <Skeleton className="h-[70px] w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
      <Card className="border-none shadow-2xl">
        <CardContent className="p-6 space-y-6">
          <Skeleton className="h-12 w-24" />
          <Card>
            <CardContent className="p-6 space-y-6">
              <Skeleton className="h-[80px] w-full rounded-xl" />
              <Skeleton className="h-4 w-full" />
            </CardContent>
          </Card>
          <Card>
            <CardContent className="p-6 space-y-6">
              <Skeleton className="h-[80px] w-full rounded-xl" />
              <Skeleton className="h-4 w-full" />
            </CardContent>
          </Card>
          <Card>
            <CardContent className="p-6 space-y-6">
              <Skeleton className="h-[80px] w-full rounded-xl" />
              <Skeleton className="h-4 w-full" />
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </div>
  );
};

export default InquiryLoader;
