import { CUSTOMER_API_ROUTES } from "@config/api";
import { post, put } from "@lib/request";
import { removeDiacritics } from "@utils/diacriticsMap";

export interface UploadUrlResponse {
  data: {
    fileId: string;
    fileName: string;
  } | null;
  status: number;
}
export interface UploadUrlToCPResponse {
  data: {
    uuid: string;
    url: string;
    action: null;
  };
  status: number;
}

export interface UploadUrlPayload {
  fileName: string;
  recordId: string;
  file: File;
  uuid: string;
  fileType: string;
}
export const uploadUrl = async ({
  file,
  fileName,
  recordId,
  uuid,
  fileType,
}: UploadUrlPayload): Promise<UploadUrlResponse> => {
  try {
    const {
      data: { url, uuid: fileUuid },
    } = await post<UploadUrlToCPResponse>(CUSTOMER_API_ROUTES.UPLOAD_COMPLAINT_FILE, {
      fileName,
      recordId,
      uuid,
      fileType,
    });
    await put(url, file, {
      "Content-Type": file.type,
      "Content-Disposition": `attachment; filename="${removeDiacritics(file.name)}"`,
    });
    return {
      data: {
        fileId: fileUuid,
        fileName,
      },
      status: 200,
    };
  } catch (error) {
    return {
      data: null,
      status: 500,
    };
  }
};
