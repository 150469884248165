import * as FAAccess from "@access/framework-agreement.access";
import {
  FrameworkAgreement,
  FrameworkAgreementIDResponse,
} from "@models/framework-agreement.model";

export async function getFrameworkAgreements({
  params,
}: {
  params?: FAAccess.FaParams;
}): Promise<{
  data: FrameworkAgreement[] | null;
  error?: string;
}> {
  return FAAccess.getFrameworkAgreements({ params });
}
export async function getFrameworkAgreementWithId(id: string): Promise<{
  data: FrameworkAgreementIDResponse | null;
  error?: string;
}> {
  return FAAccess.getFrameworkAgreementWithId(id);
}
export async function createFaBatchOrder(payload: FAAccess.CreateFABatchOrderPayload) {
  return FAAccess.createFaBatchOrder(payload);
}
