import { Icons } from "@components/icons";
import ZoomPartImage from "@components/zoom-part-image";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { MoneyFactory, MoneyUtils } from "@lib/money";
import { NumberUtils } from "@lib/number";
import { FrameworkFile } from "@models/framework-agreement.model";
import { SingleOfferLine } from "@models/offer";
import { useDialogStore } from "@store/dialog.store";
import { useInquiry } from "@store/wizard-inqury";
import { Button } from "@ui/button";
import { Card, CardContent } from "@ui/card";
import { Checkbox } from "@ui/checkbox";
import { Separator } from "@ui/separator";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line react-hooks/rules-of-hooks
const materialGroupObj = useObjectTranslations("materials");
// eslint-disable-next-line react-hooks/rules-of-hooks
const missingDataInfoObj = useObjectTranslations("missingDataInfo");
// eslint-disable-next-line react-hooks/rules-of-hooks
const certificatesObj = useObjectTranslations("certificates");

const PartSingle = ({
  part,
  index,
  disable,
  selectionDisabled,
}: {
  part: SingleOfferLine;
  index: number;
  disable?: boolean;
  selectionDisabled?: boolean;
}) => {
  const { frameworkOfferLines, singleOfferLines, addSingleOffer } = useInquiry();
  const { show } = useDialogStore();
  const handleZoomImage = () => {
    show({
      content: (
        <ZoomPartImage
          fileDownloadUrl={part.files?.[0]?.fileDownloadUrl}
          imageUrl={part.files?.[0]?.fileThumbnailUrl}
          partName={part.partName}
          materialGroup={part.materialGroup}
          material={part.material}
          articleNumber={part.articleNumber}
          nameTechnicalDrawing={part.nameTechnicalDrawing}
          revision={part.revision}
          materialCertificate={part.materialCertificate}
          tolorence={part.tolerance}
          productionMethod={part.productionMethod}
          surface={part.surface}
          comments={part.comments}
          surfaceDetailsMap={part.surfaceDetailsMap}
          allFiles={part.files as unknown as FrameworkFile[]}
        />
      ),
      side: "right",
    });
  };
  const { t } = useTranslation();
  return (
    <div>
      <Card>
        <CardContent className="sm:p-6 p-4 space-y-4">
          <div className="flex gap-4 items-center">
            <h1 className="font-bold font-display text-lg">
              {t("dashboard-position")} {index}
            </h1>
            <h2 className="font-bold font-display text-muted-foreground">
              {part?.partName || "-"}
            </h2>
            <Button variant={"link"} onClick={handleZoomImage}>
              <Icons.openCart className="size-4 text-muted-foreground" />
              <span className="ml-2 text-sm font-bold font-display">
                {t("dashboard-view_part_details")}
              </span>
            </Button>
          </div>
          <div className="flex gap-4 items-center">
            <Button
              onClick={handleZoomImage}
              variant={"ghost"}
              className="w-[5rem] h-[5rem] p-0 relative"
            >
              <div className="hover:bg-foreground absolute inset-0 opacity-30 rounded-lg flex justify-center items-center">
                <Icons.expand className="size-5 text-background" />
              </div>
              {part.files?.[0]?.fileThumbnailUrl ? (
                <div
                  className="w-[5rem] h-[5rem] border rounded-lg bg-cover"
                  style={{ backgroundImage: `url(${part.files?.[0]?.fileThumbnailUrl})` }}
                />
              ) : (
                <Icons.imageThumbnail className="w-[5rem] h-[5rem]" />
              )}
            </Button>
            <div className="text-sm col-span-8 grid grid-cols-6">
              <div className="flex items-center col-span-1">
                <div>
                  <div className="text-muted-foreground">
                    {t("dashboard-part_number")}:
                  </div>
                  <h4 className="font-bold font-display">{part?.partNumber || "-"}</h4>
                </div>
                <Separator orientation="vertical" className="w-[1px] h-6 ml-4" />
              </div>
              <div className="flex items-center col-span-1">
                <div>
                  <div className="text-muted-foreground">
                    {t("parts-overview_article-number")}:
                  </div>
                  <h4 className="font-bold font-display">{part?.articleNumber || "-"}</h4>
                </div>
                <Separator orientation="vertical" className="w-[1px] h-6 ml-4" />
              </div>
              <div className="flex items-center col-span-4 ml-4">
                <div>
                  <div className="text-muted-foreground">
                    {t("wizard-table_additional-prod-processes")}:
                  </div>
                  <ul className="font-bold font-display list-disc list-inside">
                    {part.surfaceDetailsMap
                      ? part.surface?.map((surf, index) => {
                          return (
                            <li key={index}>
                              {`${surf} (${part.surfaceDetailsMap[surf]})`}{" "}
                            </li>
                          );
                        }) || "-"
                      : "-"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Might have to remove after the discussion with claudius */}
          {part.missingDataInfo && part.missingDataInfo?.length > 1 ? (
            <div className="p-6 border rounded-lg flex items-center space-x-4 bg-[#FFF2E8] mb-2">
              <Icons.warningCustom className="text-[#FD7E14]" />
              <span className="text-sm">
                {part.missingDataInfo?.includes("3DModel") ? (
                  <span> {missingDataInfoObj["3DModel"]} </span>
                ) : null}
                {part.missingDataInfo?.includes("Other")
                  ? `, ${part.missingDataInfoMessage}`
                  : null}
              </span>
            </div>
          ) : null}
          {part.comments ? (
            <div className="p-6 border rounded-lg flex items-center space-x-4 bg-[#FFF2E8] mb-2">
              <Icons.warningCircle className="text-[#FD7E14]" />
              <span className="text-sm space-x-1">
                <span className="font-bold font-display">{t("please_note")}:</span>
                <span>{part.comments}</span>
              </span>
            </div>
          ) : null}
          {/* New version */}
          {frameworkOfferLines.find(
            (fa) => fa.hasSingleOrder && fa.partNumber === part.partNumber,
          )?.partName ? (
            <div className="p-6 border rounded-lg flex items-center space-x-4 mb-2">
              <Icons.excliMark className="fill-muted-foreground" />
              <span className="text-sm">{t("dashboard-this_part_is_on_fa")}</span>
            </div>
          ) : (
            <>
              <div className="overflow-x-scroll">
                <div className="w-[1220px]">
                  {/* Menu start */}
                  <div className="flex gap-4 text-sm bg-muted text-muted-foreground p-4">
                    <div className="-ml-1">
                      <Icons.cart />
                    </div>
                    <div className="grid w-full grid-cols-6 gap-6 items-center">
                      <div>{t("offers-single-order-sidebar_origin")}</div>
                      <div>{t("offers-single-order-table-columns_material")}</div>
                      <div>{t("fa-contract-details_delivery")}</div>
                      <div className="text-right">
                        {t("fa-contract-details_total-quantity")}
                      </div>
                      <div className="text-right">{t("unit_price")}</div>
                      <div className="text-right">
                        {t("fa-details-sidebar_total-cost")}
                      </div>
                    </div>
                  </div>

                  {/* Menu end */}
                  {/* Line items start */}
                  {part?.quantities &&
                    part.quantities.length > 0 &&
                    part.quantities.map((qunatity, index) => {
                      return (
                        <button
                          key={index}
                          disabled={
                            selectionDisabled ||
                            disable ||
                            !!singleOfferLines?.find(
                              (so) =>
                                so.uuid === part.uuid && // Find the matching part
                                so.selectedOfferLineId && // Ensure there is a selectedOfferLineId
                                so.selectedOfferLineId !== qunatity.offerLineId,
                            )
                          }
                          className="w-full"
                          onClick={() => addSingleOffer(part, qunatity.offerLineId)}
                        >
                          <div className="flex w-full gap-4 items-start p-4 hover:bg-muted/50">
                            <div className="cell self-start">
                              <Checkbox
                                checked={
                                  !!singleOfferLines.find(
                                    (so) =>
                                      so.selectedOfferLineId === qunatity.offerLineId,
                                  )
                                }
                                disabled={
                                  selectionDisabled ||
                                  disable ||
                                  !!singleOfferLines?.find(
                                    (so) =>
                                      so.uuid === part.uuid && // Find the matching part
                                      so.selectedOfferLineId && // Ensure there is a selectedOfferLineId
                                      so.selectedOfferLineId !== qunatity.offerLineId,
                                  )
                                }
                              />
                            </div>
                            <div className="grid grid-cols-6 w-full gap-4 text-left">
                              <div className="origin-cell ml-1">
                                <div className="font-bold font-display text-sm">
                                  {qunatity?.originCountry || "-"}
                                </div>
                              </div>
                              <div className="material-cell ml-1">
                                <div className="font-bold font-display text-sm">
                                  {
                                    materialGroupObj[
                                      part?.materialGroup?.replace(/\s/g, "")
                                    ]
                                  }{" "}
                                  {part?.material && " -"} {part?.material || ""}
                                </div>
                              </div>
                              <div className="cell text-sm space-y-2 ml-1">
                                <p className="font-bold font-display">
                                  {qunatity?.minProductionDays ? (
                                    <>
                                      {Math.floor(qunatity.minProductionDays / 7)}
                                      <span className="ml-1">{t("global_weeks")}</span>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </p>
                              </div>
                              <div className="cell text-sm space-y-2 ml-1 text-right">
                                <div className="text-sm font-bold font-display">
                                  {qunatity?.quantity
                                    ? NumberUtils.format(qunatity.quantity)
                                    : "-"}
                                </div>
                              </div>
                              <div className="cell text-sm space-y-2 ml-1 text-right">
                                <div className="space-x-1 flex items-center justify-end">
                                  {qunatity.priceNetBeforeDiscount ===
                                  qunatity.priceNet ? (
                                    <div className="text-sm font-bold font-display">
                                      {qunatity.priceNet
                                        ? MoneyUtils.format(
                                            MoneyFactory.create(
                                              qunatity.priceNet,
                                              qunatity.currencyISOCode,
                                            ),
                                          )
                                        : "-"}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="line-through text-sm">
                                        {MoneyUtils.format(
                                          MoneyFactory.create(
                                            qunatity.priceNetBeforeDiscount,
                                            qunatity.currencyISOCode,
                                          ),
                                        )}
                                      </div>
                                      <span className="font-bold font-display text-sm text-brand">
                                        {MoneyUtils.format(
                                          MoneyFactory.create(
                                            qunatity.priceNet,
                                            qunatity.currencyISOCode,
                                          ),
                                        )}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="cell text-sm space-y-2 ml-1 text-right">
                                <div className="text-sm font-bold font-display ">
                                  {qunatity?.totalPriceNet
                                    ? MoneyUtils.format(
                                        MoneyFactory.create(
                                          qunatity.totalPriceNet,
                                          qunatity.currencyISOCode,
                                        ),
                                      )
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Separator />
                        </button>
                      );
                    })}
                  {/* Line items end */}
                </div>
              </div>
              {part.certificateLines?.length > 0 && (
                <div className="bg-muted p-6 rounded-xl">
                  <h1 className="font-bold font-display text-xl">
                    {t("dashboard-additional_services")}
                  </h1>
                  <div className="mt-7">
                    {part.certificateLines?.map((certificateLine, index) => {
                      return (
                        <div key={index} className="flex items-start w-full">
                          <div className="mr-4">
                            <Checkbox
                              checked={
                                !!singleOfferLines.find((so) => so.lineId === part.lineId)
                              }
                              disabled
                            />
                          </div>
                          <div className="grid grid-cols-6 gap-6 text-sm w-full">
                            <div className="col-span-3">
                              <span className="flex gap-2">
                                <span className="flex gap-2">
                                  {certificateLine.partName ? (
                                    <div className="font-bold font-display">
                                      {certificatesObj[certificateLine.partName]}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                  {certificateLine.materialGroup ||
                                  certificateLine.material ? (
                                    <div>|</div>
                                  ) : null}
                                  {
                                    materialGroupObj[
                                      certificateLine.materialGroup?.replace(/\s/g, "")
                                    ]
                                  }{" "}
                                </span>
                                <span>
                                  {certificateLine.material && " -"}{" "}
                                  {certificateLine.material || ""}
                                </span>
                              </span>
                            </div>
                            <div className="font-bold font-display text-right">
                              {" "}
                              {NumberUtils.format(certificateLine.quantity)}
                            </div>
                            <div className="font-bold font-display text-right">
                              {certificateLine.priceNet
                                ? MoneyUtils.format(
                                    MoneyFactory.create(
                                      certificateLine.priceNet,
                                      part.currencyISOCode,
                                    ),
                                  )
                                : "-"}
                            </div>
                            <div className="font-bold font-display text-right">
                              {certificateLine.totalPriceNet
                                ? MoneyUtils.format(
                                    MoneyFactory.create(
                                      certificateLine.totalPriceNet,
                                      part.currencyISOCode,
                                    ),
                                  )
                                : "-"}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PartSingle;
