import { Icons } from "@components/icons";
import CertificatesForm from "@components/new-user-dialog/steps/certificates.form";
import CompanyForm from "@components/new-user-dialog/steps/company.form";
import DocumentsForm from "@components/new-user-dialog/steps/documents.form";
import InquiriesForm from "@components/new-user-dialog/steps/inquiries.form";
import ManufacturingForm from "@components/new-user-dialog/steps/manufacturing.form";
import PurchasingForm from "@components/new-user-dialog/steps/purchasing.form";
import RegionForm from "@components/new-user-dialog/steps/region.form";
import SourceForm from "@components/new-user-dialog/steps/source.form";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Steps } from "@store/wizard.store";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Badge } from "@ui/badge";
import { Progress } from "@ui/progress";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@ui/tabs";
import { useTranslation } from "react-i18next";

const NewUserDialogForm = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const {
    wizzardState: { currentStep, stepsStatuses },
    setCurrentStep,
  } = useNewUserWizardStore((state) => state);

  return (
    <>
      <Tabs
        className="!border-none"
        value={currentStep}
        onValueChange={(e) => setCurrentStep(e as Steps)}
      >
        <div className="py-4">
          <Progress
            value={
              {
                1: 12.5,
                2: 25,
                3: 37.5,
                4: 50,
                5: 62.5,
                6: 75,
                7: 87.5,
                8: 100,
              }[currentStep]
            }
            className="my-2 rounded-none h-1"
          />
          {/* TODO: For success page the progress bar and steps should be hidden. */}
          <TabsList className="grid grid-cols-8 max-w-7xl m-auto transition-all h-12 my-8 bg-background border-none mb-0">
            <TabsTrigger
              value="1"
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[1] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge variant={"brand"} className="size-7 leading-loose">
                    1
                  </Badge>
                )}
              </span>
              <span className="md:flex hidden">{t("dashboard-company")}</span>
              <div className="md:ml-2 -ml-[10px]">
                <Icons.chevronRight className="size-6" />
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="2"
              disabled={!stepsStatuses[1]}
              onClick={() => {
                setCurrentStep("2");
              }}
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[2] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge
                    variant={
                      stepsStatuses[2] || currentStep === "2" ? "brand" : "outline"
                    }
                    className="size-7 leading-loose"
                  >
                    2
                  </Badge>
                )}
              </span>{" "}
              <span className="md:flex hidden">{t("dashboard-purchasing")}</span>
              <div className="md:ml-2 -ml-[10px]">
                <Icons.chevronRight className="ml-2 size-6" />
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="3"
              disabled={!stepsStatuses[2]}
              onClick={() => {
                setCurrentStep("3");
              }}
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[3] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge
                    variant={
                      stepsStatuses[3] || currentStep === "3" ? "brand" : "outline"
                    }
                    className="size-7 leading-loose"
                  >
                    3
                  </Badge>
                )}
              </span>{" "}
              <span className="md:flex hidden">{t("dashboard-manufacturing")}</span>
              <div className="md:ml-2 -ml-[10px]">
                <Icons.chevronRight className="ml-2 size-6" />
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="4"
              disabled={!stepsStatuses[3]}
              onClick={() => {
                setCurrentStep("4");
              }}
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[4] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge
                    variant={
                      stepsStatuses[4] || currentStep === "4" ? "brand" : "outline"
                    }
                    className="size-7 leading-loose"
                  >
                    4
                  </Badge>
                )}
              </span>{" "}
              <span className="md:flex hidden">{t("dashboard-region")}</span>
              <div className="md:ml-2 -ml-[10px]">
                <Icons.chevronRight className="ml-2 size-6" />
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="5"
              disabled={!stepsStatuses[4]}
              onClick={() => {
                setCurrentStep("5");
              }}
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[5] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge
                    variant={
                      stepsStatuses[5] || currentStep === "5" ? "brand" : "outline"
                    }
                    className="size-7 leading-loose"
                  >
                    5
                  </Badge>
                )}
              </span>{" "}
              <span className="md:flex hidden">{t("dashboard-inquiries")}</span>
              <div className="md:ml-2 -ml-[10px]">
                <Icons.chevronRight className="ml-2 size-6" />
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="6"
              disabled={!stepsStatuses[5]}
              onClick={() => {
                setCurrentStep("6");
              }}
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[6] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge
                    variant={
                      stepsStatuses[6] || currentStep === "6" ? "brand" : "outline"
                    }
                    className="size-7 leading-loose"
                  >
                    6
                  </Badge>
                )}
              </span>{" "}
              <span className="md:flex hidden">{t("dashboard-certificates")}</span>
              <div className="md:ml-2 -ml-[10px]">
                <Icons.chevronRight className="ml-2 size-6" />
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="7"
              disabled={!stepsStatuses[6]}
              onClick={() => {
                setCurrentStep("7");
              }}
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[7] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge
                    variant={
                      stepsStatuses[7] || currentStep === "7" ? "brand" : "outline"
                    }
                    className="size-7 leading-loose"
                  >
                    7
                  </Badge>
                )}
              </span>{" "}
              <span className="md:flex hidden">{t("dashboard-documents")}</span>
              <div className="md:ml-2 -ml-[10px]">
                <Icons.chevronRight className="ml-2 size-6" />
              </div>
            </TabsTrigger>
            <TabsTrigger
              value="8"
              disabled={!stepsStatuses[7]}
              onClick={() => {
                setCurrentStep("8");
              }}
              className="sm:text-sm text-xxs !shadow-none border-none !bg-background"
            >
              <span className="mr-2">
                {stepsStatuses[8] ? (
                  <Icons.checkBlue className="size-6" />
                ) : (
                  <Badge
                    variant={
                      stepsStatuses[8] || currentStep === "8" ? "brand" : "outline"
                    }
                    className="size-7 leading-loose"
                  >
                    8
                  </Badge>
                )}
              </span>{" "}
              <span className="md:flex hidden">{t("dashboard-source")}</span>
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="1">
          <CompanyForm />
        </TabsContent>
        <TabsContent value="2">
          <PurchasingForm />
        </TabsContent>
        <TabsContent value="3">
          <ManufacturingForm />
        </TabsContent>
        <TabsContent value="4">
          <RegionForm />
        </TabsContent>
        <TabsContent value="5">
          <InquiriesForm />
        </TabsContent>
        <TabsContent value="6">
          <CertificatesForm />
        </TabsContent>
        <TabsContent value="7">
          <DocumentsForm />
        </TabsContent>
        <TabsContent value="8">
          <SourceForm />
        </TabsContent>
      </Tabs>
    </>
  );
};

export default NewUserDialogForm;
