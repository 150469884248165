import { OFFER_API_ROUTES } from "@config/api";
import { fetchWithToken, patch, patchWithToken } from "@lib/request";
import {
  AcceptOfferData,
  AcceptOfferResponse,
  Addresses,
  DefaultContacts,
  FrameworkOrderDetailsPayload,
  GetOfferDetailsResponse,
  GetOfferResponse,
  Offer,
  OfferDetailsResponse,
  OfferSearchValue,
  OfferUpsertPayload,
  OrderDetails,
  UpsertOfferPayload,
  UpsertOfferResponse,
} from "@models/offer";

export const offerStatusMapped = {
  Draft: "Wizardnotfinished",
  "In examination": "ProfiCheck",
  "Feedback required": "Waitingforanswer",
  "Calculation running": "Partnerofferreceived",
  "Offer updated": "UpdateSent",
  "Offer created": "Offersent",
  "Price negotiation": "InNegotiation",
  Ordered: "Offeraccepted",
  Canceled: "Lost",
};

export const offerSearchValues: OfferSearchValue[] = [
  "All",
  "Calculation running",
  "Feedback required",
  "Offer created",
  "Offer updated",
  "Price negotiation",
  "Canceled",
  "Ordered",
];

export interface OfferParams {
  startDate?: string | null;
  endDate?: string | null;
  status: OfferSearchValue | null;
  searchFilter?: string | null;
}

export async function getOffers({ params }: { params?: OfferParams }): Promise<{
  data: Offer[] | null;
  error?: string;
}> {
  let response: Offer[] | null = null;
  let errorResponse: string | undefined;
  const url = new URL(OFFER_API_ROUTES.GET_OFFERS);
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === "object") return;
      url.searchParams.append(key, value);
    });
  }
  try {
    const { data: result } = await fetchWithToken<GetOfferResponse>(url.toString());
    if (result?.data) {
      response = result?.data;
    }
  } catch (error: any) {
    errorResponse = error.message;
  }
  return {
    data: response,
    error: errorResponse,
  };
}
export async function getLeadInProgress(recordId: string): Promise<{
  data: Offer[] | null;
  error?: string;
}> {
  let response: Offer[] | null = null;
  let errorResponse: string | undefined;
  try {
    const { data: result } = await fetchWithToken<GetOfferResponse>(
      OFFER_API_ROUTES.GET_LEAD_IN_PROGRESS(recordId),
    );
    if (result?.data) {
      response = result?.data;
    }
  } catch (error: any) {
    errorResponse = error.message;
  }
  return {
    data: response,
    error: errorResponse,
  };
}

export async function getOfferDetails(offerId: string): Promise<{
  data: OfferDetailsResponse | null;
  error?: string;
}> {
  let response: OfferDetailsResponse | null = null;
  let errorResponse: string | undefined;
  try {
    const { data: result } = await fetchWithToken<GetOfferDetailsResponse>(
      OFFER_API_ROUTES.GET_ORDER_DETAILS_ID(offerId),
    );
    if (result?.data) {
      response = result?.data;
    }
  } catch (error: any) {
    errorResponse = error.message;
  }
  return {
    data: response,
    error: errorResponse,
  };
}

export interface AcceptOfferPayload {
  order: OrderDetails | null;
  frameworkAgreement: FrameworkOrderDetailsPayload | null;
  defaultContacts: DefaultContacts;
  addresses: Addresses;
}

export async function upsertOffer({ payload }: { payload: OfferUpsertPayload }): Promise<{
  data: UpsertOfferPayload | null;
  error?: string;
}> {
  let response: UpsertOfferPayload | null = null;
  let errorResponse: string | undefined;
  try {
    const { data: result } = await patch<UpsertOfferResponse>(
      OFFER_API_ROUTES.UPSERT_OFFER,
      payload,
    );
    response = result;
  } catch (error: any) {
    errorResponse = error.message;
  }
  return {
    data: response,
    error: errorResponse,
  };
}
export async function acceptOfferProcess({
  payload,
}: {
  payload: AcceptOfferPayload;
}): Promise<{
  data: AcceptOfferData | null;
  error?: string;
}> {
  let response: AcceptOfferData | null = null;
  let errorResponse: string | undefined;
  try {
    const { data: result } = await patchWithToken<AcceptOfferResponse>(
      OFFER_API_ROUTES.PLACE_OFFER,
      payload,
    );
    response = result;
  } catch (error: any) {
    errorResponse = error.message;
  }
  return {
    data: response,
    error: errorResponse,
  };
}
