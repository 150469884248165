import * as CookieAccess from "@access/cookie-storage";
import { storageKeys } from "@config/common";
import { axiosConfig } from "@config/request";
import axios, { AxiosRequestConfig } from "axios";

axios.defaults.timeout = 60000;

export interface ApiResponse<T> {
  status: number;
  data?: T;
  error?: Error;
}

axios.interceptors.request.use(async (request) => {
  const xUserId = await CookieAccess.getCookie(storageKeys.userId);
  // ignore token request for auth0 login
  if (xUserId && request.url !== "https://login.cnc24.com/oauth/token") {
    request.headers.set("X-Track-Id", xUserId);
  }
  return request;
});

export const post = async <T>(url: string, payload?: unknown): Promise<T> => {
  const { data } = await axios.post<T>(url, payload || null, {
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
  });
  return data;
};

export const patch = async <T>(url: string, payload?: unknown): Promise<T> => {
  const { data } = await axios.patch<T>(url, payload || null, {
    headers: { "Cache-Control": "no-cache" },
  });
  return data;
};
export const patchWithToken = async <T>(url: string, payload?: unknown): Promise<T> => {
  const { token: tokenFromStorage } = await getToken();
  const { data } = await axios.patch<T>(url, payload || null, {
    headers: { "Cache-Control": "no-cache", Authorization: `Bearer ${tokenFromStorage}` },
  });
  return data;
};

export const put = async <T>(
  url: string,
  payload?: unknown,
  headers?: AxiosRequestConfig["headers"],
): Promise<T> => {
  const { data } = await axios.put<T>(url, payload || null, {
    headers,
  });
  return data;
};

export const fetcher = async <T>(
  url: string,
  headers?: AxiosRequestConfig["headers"],
): Promise<T> => {
  const { data } = await axios.get(url, {
    headers,
  });
  return data;
};

export const fetcherWithToken = async <T>(
  url: string,
  headers?: AxiosRequestConfig["headers"],
): Promise<T> => {
  const { token } = await getToken();
  const { data } = await axios.get(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const remove = async <T>(url: string): Promise<T> => {
  const { data } = await axios.delete<T>(url);
  return data;
};

export const postForm = async <T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any,
): Promise<T> => {
  const { data } = await axios.post<T>(url, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

export const fetchWithToken = async <T>(
  url: string,
  token?: string,
): Promise<ApiResponse<T>> => {
  const { token: tokenFromStorage, error } = await getToken();
  if (error) {
    return {
      error: new Error(error.error_description),
      status: 401,
    };
  }
  const { data, status } = await axios.get<T>(
    url,
    axiosConfig(token ? token : tokenFromStorage),
  );
  return { data: data, status };
};

export const postWithToken = async <T>(
  url: string,
  token?: string,
  payload?: unknown,
): Promise<ApiResponse<any>> => {
  const { token: tokenFromStorage, error } = await getToken();
  if (error) {
    return {
      error: new Error(error.error_description),
      data: null,
      status: 401,
    };
  }
  const { data, status } = await axios.post<T>(
    url,
    payload || null,
    axiosConfig(token ? token : tokenFromStorage),
  );
  return { data: data, status };
};

export const putWithToken = async <T>(
  url: string,
  token?: string,
  payload?: unknown,
): Promise<ApiResponse<T>> => {
  const { token: tokenFromStorage } = await getToken();
  const { data, status } = await axios.put<T>(
    url,
    payload || null,
    axiosConfig(token ? token : tokenFromStorage),
  );
  return { data: data, status };
};

export const deleteWithToken = async <T>(
  url: string,
  token: string,
  payload?: unknown,
): Promise<ApiResponse<T>> => {
  const { data, status } = await axios.delete<T>(url, {
    ...axiosConfig(token),
    data: payload,
  });
  return { data: data, status };
};

export const getToken = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  // Currently there is two ways to login:
  // Basic auth: email and passowrd
  // Magic link: Contains two properties tmagic-token(access token) & x-userId(auth0 token)
  const magicToken = urlParams.get("tmagic");
  const xUserId = urlParams.get("x-userId");
  if (magicToken && xUserId) {
    CookieAccess.setCookie(storageKeys.token, magicToken);
    CookieAccess.setCookie(storageKeys.userId, xUserId);
  }

  const token = CookieAccess.getCookie(storageKeys.token);

  if (!token) {
    return {
      token: null,
      error: {
        error: "Token missing",
        error_description: "Token is mission from storage",
      },
    };
  }

  return {
    token: token as string,
    error: null,
  };
};
