import { zodResolver } from "@hookform/resolvers/zod";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/select";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as z from "zod";

const accountDetailsWizard = z.object({
  annualPurchasingVolume: z.number({
    required_error: t("error_field-required") as string,
  }),
  numberOfPurchasers: z
    .number({
      required_error: t("error_field-required") as string,
    })
    .optional(),
  numberOfSuppliers: z.string().optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const PurchasingForm = () => {
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    numberOfSuppliers: accountDetails?.numberOfSuppliers?.toString() || undefined,
    annualPurchasingVolume: accountDetails.annualPurchasingVolume || undefined,
    numberOfPurchasers: accountDetails?.numberOfPurchasers || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });

  async function onSubmit({
    numberOfSuppliers,
    annualPurchasingVolume,
    numberOfPurchasers,
  }: z.infer<typeof accountDetailsWizard>) {
    setWizardDetails(
      "numberOfSuppliers",
      numberOfSuppliers ? parseInt(numberOfSuppliers) : undefined,
    );
    setWizardDetails("numberOfPurchasers", numberOfPurchasers);
    setWizardDetails("annualPurchasingVolume", annualPurchasingVolume);
    setStepStatus("2", true);
    setCurrentStep("3");
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[10vh] sm:min-h-[34vh] mx-auto sm:px-8 md:px-6 px-4 space-y-10 pb-40 sm:pb-0">
            <FormField
              control={form.control}
              defaultValue={accountDetails?.annualPurchasingVolume}
              name="annualPurchasingVolume"
              render={({ field }) => (
                <FormItem className="space-y-3 !mt-6">
                  <FormLabel className="font-bold font-display sm:text-lg text-sm">
                    {t("dashboard-purchasing_volume_question")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value))}
                      className="max-w-lg"
                      placeholder={
                        t("dashboard-please_specify_your_puchasing_vol") as string
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="numberOfPurchasers"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-bold font-display sm:text-lg text-sm">
                    {t("dashboard-current_number_of_purchasers")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value))}
                      className="max-w-lg"
                      placeholder={t("dashboard-please_specify_no_purchasers") as string}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="numberOfSuppliers"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select
                      value={field.value?.toString()}
                      onValueChange={field.onChange}
                    >
                      <FormLabel className="font-bold font-display sm:text-lg text-sm">
                        {t("dashboard-suppliers_utilising")}
                      </FormLabel>
                      <SelectTrigger className="max-w-lg">
                        <SelectValue
                          placeholder={
                            <span className="text-muted-foreground">
                              {t("dashboard-please_specify_suppliers_no")}
                            </span>
                          }
                        />
                      </SelectTrigger>
                      <SelectContent className="max-h-96">
                        {["1-5", "6-20", "21-50", "51-100", "+101"].map((number) => {
                          return (
                            <SelectItem key={number} value={number}>
                              {number}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-between p-8">
              <Button
                onClick={() => setCurrentStep("1")}
                variant={"outline"}
                type="button"
              >
                {t("global_go-back-btn")}
              </Button>
              <Button>{t("global_continue-btn")}</Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default PurchasingForm;
