import { useDialogStore } from "@store/dialog.store";
import { Sheet as SheetDialog, SheetContent } from "@ui/sheet";

export default function Sheet() {
  const { showDialog, triggerDialog, content, side } = useDialogStore((state) => state);
  return (
    <>
      <SheetDialog open={showDialog} onOpenChange={() => triggerDialog(false)}>
        <SheetContent
          side={side}
          callbackCloseHandler={() => triggerDialog(false)}
          className={side === "right" ? "overflow-y-scroll" : ""}
        >
          {content}
        </SheetContent>
      </SheetDialog>
    </>
  );
}
