import { Money } from "@models/money";

/**
 * Factory for creating Money objects.
 */
export class MoneyFactory {
  /**
   * Creates a Money object.
   * @param {string} amount amount
   * @param {string} currency currency
   * @return {Money} money object
   */
  public static create(amount = 0, currency = "EUR"): Money {
    return { amount, currency };
  }
}

/**
 * Utility class for Money.
 */
export class MoneyUtils {
  /**
   * Returns the sum of two Money objects.
   *
   * @param money1 the first summand
   * @param money2 the second summand
   * @returns the sum
   */
  static add(money1: Money, money2: Money): Money {
    const sum: Money = MoneyFactory.create();

    sum.amount = +(money1.amount + money2.amount).toFixed(2);
    sum.currency = money1.currency;

    return sum;
  }

  /**
   * Returns the difference of two Money objects.
   *
   * @param minuend the minuend
   * @param subtrahend the subtrahend
   * @returns the difference = minuend - subtrahend
   */
  static subtract(minuend: Money, subtrahend: Money): Money {
    const difference: Money = MoneyFactory.create();

    difference.amount = +(minuend.amount - subtrahend.amount).toFixed(2);
    difference.currency = minuend.currency;

    return difference;
  }

  /**
   * Multiplies a Money object by a scalar.
   *
   * @param scalar the scalar to multiply
   * @param money the Money object to multiply
   * @returns the result of the scalar multiplication
   */
  static multScalar(scalar: number, money: Money): Money {
    const result: Money = MoneyFactory.create();

    result.amount = +(scalar * money.amount).toFixed(2);
    result.currency = money.currency;

    return result;
  }

  static format(money: Money): string {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: money.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(money.amount);
  }
}
