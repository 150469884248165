import NewUserDialog from "@components/new-user-dialog";
import { Sidebar } from "@components/sidebar/sidebar";
import i18n from "@config/i18n";
import { PAGES_ROUTES } from "@config/routes";
import { useAuth } from "@hooks/useAuth";
import { Loader } from "@ui/loader";
import { convertLanguageToKey } from "@utils/language";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";

export const AppContainer = () => {
  const { user, isLoading } = useAuth();
  const [params] = useSearchParams();
  const { pathname } = useLocation();
  const language: "en" | "de" | null | string = params.get("lang");
  useEffect(() => {
    if (user && !language) {
      i18n.changeLanguage(convertLanguageToKey(user.lang));
    }
  }, [language, user]);

  return (
    <>
      <AnimatePresence>
        {isLoading ? (
          <Loader
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            size="xl"
            className="fixed inset-0 z-50 w-full min-h-screen flex justify-center items-center bg-background"
          />
        ) : null}
      </AnimatePresence>
      <div className="flex">
        <Sidebar />
        <div className="overflow-y-auto min-h-screen container mt-20 sm:mt-4 px-10">
          {/* TODO: Rules to display Account Qualiffication Modal
             - Check if the user already has submitted the AQM form
             - User's email is verified!?
             - User is role is 1st Purchase Manager and Head of Purchasing. (not sure if we have this roles. Maybe they are called differently
             - How do we know if the user an existing user or new one (this is to display the correct success modal (One is for disqualified and the other is for existing user)
          */}
          {pathname === PAGES_ROUTES.MAIN && user && user.accountQualificationNeeded && (
            <NewUserDialog />
          )}
          <Outlet />
        </div>
      </div>
    </>
  );
};
