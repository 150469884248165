import "@src/styles/sidebar.css";

import { Icons } from "@components/icons";
import { PAGES_ROUTES } from "@config/routes";

import Menu from "./menu";
import MenuMobile from "./menu-mobile";
export interface SectionSteps {
  path: string;
  title: string;
  icon: JSX.Element;
}
export const sections: SectionSteps[] = [
  {
    title: "dashboard_dashboard",
    path: PAGES_ROUTES.MAIN,
    icon: <Icons.reports className="size-6 text-muted-foreground" />,
  },
  {
    title: "main-navigation_offers",
    path: PAGES_ROUTES.OFFERS,
    icon: <Icons.inquiryIcon className="size-6 text-muted-foreground" />,
  },
  {
    title: "main-navigation_orders",
    path: PAGES_ROUTES.ORDERS,
    icon: <Icons.package className="size-6 text-muted-foreground" />,
  },
  {
    title: "main-navigation_framework-agreements",
    path: PAGES_ROUTES.FRAMEWORK_AGREEMENTS,
    icon: <Icons.consumable className="size-6 text-muted-foreground" />,
  },
];

export function Sidebar() {
  return (
    <>
      <Menu />
      <MenuMobile />
    </>
  );
}
