import { cn } from "@lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-primary-foreground shadow",
        secondary: "border-transparent bg-secondary text-secondary-foreground ",
        success: "border-transparent bg-success text-success-foreground ",
        brand:
          "border-transparent bg-brand text-primary-foreground hover:bg-brand/80 disabled:bg-muted",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow ",
        outline: "text-foreground",
        blue: "border-transparent bg-light-blue text-foreground shadow  ",
        orange: "border-transparent bg-light-orange text-black shadow ",
        yellow: "border-transparent bg-light-yellow text-black shadow ",
        green: "border-transparent bg-light-green text-black shadow ",
        "light-red": "border-transparent bg-light-red text-black shadow ",
        "light-lavender": "border-transparent bg-light-lavender text-black shadow ",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
