import { Icons } from "@components/icons";
import NavigationLink from "@components/sidebar/nav-link-sidebar";
import { sections } from "@components/sidebar/sidebar";
import SidebarItem from "@components/sidebar/sidebar-item";
import { UserNav } from "@components/user-nav";
import { PAGES_ROUTES } from "@config/routes";
import { useWindowSize } from "@hooks/useWindowSize";
import { useSidebarStore } from "@store/sidebar.store";
import { Button } from "@ui/button";
import { Separator } from "@ui/separator";
import { Sheet, SheetContent, SheetOverlay, SheetPortal } from "@ui/sheet";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const MenuMobile = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { showSidebar: sidebarToogleState, toggleSidebar } = useSidebarStore(
    (state) => state,
  );
  const handleCloseMenu = () => {
    // Wait for animation to finish
    setTimeout(() => {
      toggleSidebar("close");
    }, 1000);
  };
  useEffect(() => {
    handleCloseMenu();
  }, [location]);

  const { width } = useWindowSize();

  if (width && width >= 512) return null;

  return (
    <div>
      <div className="w-full fixed p-4 flex justify-between bg-backround-bar backdrop-blur-xl z-50 ">
        <Link to={PAGES_ROUTES.MAIN}>
          <Icons.cncLogo className="w-20" />
        </Link>
        <Button onClick={() => toggleSidebar()} variant={"ghost"} className="text-white">
          <Icons.menu className="w-6 h-6" />
        </Button>
      </div>
      <Sheet
        open={sidebarToogleState === "open" ? true : false}
        onOpenChange={(e) => {
          toggleSidebar(e === true ? "open" : "close");
        }}
      >
        <SheetPortal>
          <SheetOverlay className="backdrop-blur-none bg-transparent" />
          <SheetContent
            closedBtn={false}
            className="w-full top-[70px] h-[calc(100%-70px)] bg-backround-bar border-none"
          >
            <div className="flex flex-col justify-between h-full">
              <div className="flex flex-col space-y-10 my-6">
                <div className="flex flex-col space-y-2">
                  {sections.map(({ title, path, icon }) => (
                    <NavigationLink to={path} key={title}>
                      {({ active }) => {
                        return (
                          <SidebarItem
                            className="sidebar--item-mobile"
                            icon={icon}
                            header={title}
                            active={active}
                          />
                        );
                      }}
                    </NavigationLink>
                  ))}
                </div>
              </div>
              <div className=" w-full p-4 pb-6 ">
                <div className="mb-4">
                  <div className="flex flex-col mb-3">
                    <Link
                      to="https://www.cnc24.com/contact/"
                      target="_blank"
                      className="flex items-center justify-start hover:text-brand"
                    >
                      <Icons.questionMarkFat className="fill-muted-foreground w-5 h-5 hover:text-brand" />
                      <div className="text-sm font-bold opacity-50 hover:text-white hover:no-underline ml-2 capitalize text-white">
                        {t("secondary-navigation_help")}
                      </div>
                    </Link>
                  </div>
                  <div className="group">
                    <Link
                      to={PAGES_ROUTES.PROFILE}
                      className="flex items-center justify-center md:justify-start gap-3"
                    >
                      <Icons.userAddPlus className="group-hover:fill-brand size-6" />
                      <h1 className="font-bold group-hover:text-brand hover:no-underline capitalize text-white">
                        {t("dashboard-invite_team_members")}
                      </h1>
                    </Link>
                  </div>
                  <Separator className="my-4 bg-background" />
                  <UserNav className="sidebar-usernav-mobile" />
                </div>
              </div>
            </div>
          </SheetContent>
        </SheetPortal>
      </Sheet>
    </div>
  );
};

export default MenuMobile;
