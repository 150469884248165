import { AccountDetails } from "@access/company.access";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export type Steps = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
type StepStatus = {
  [key in Steps]: boolean;
};

interface WizzardState {
  accountDetails: AccountDetails;
  success?: "qualified" | "not_qualified" | null;
  wizzardState: {
    currentStep: Steps;
    stepsStatuses: StepStatus;
  };
  setCurrentStep: (value: Steps) => void;
  setWizardDetails: <T extends keyof AccountDetails>(
    orderProperty: T,
    value: AccountDetails[T],
  ) => void;
  setStepStatus: (step: Steps, value: boolean) => void;
  setSuccess: (value: "qualified" | "not_qualified") => void;
  reset: () => void;
}

export const useNewUserWizardStore = create<WizzardState>()(
  devtools(
    immer((set) => ({
      wizzardState: {
        currentStep: "1",
        stepsStatuses: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
        },
      },
      accountDetails: {
        annualPurchasingVolume: undefined,
      },
      setCurrentStep(value) {
        set(
          (state: WizzardState) => {
            state.wizzardState.currentStep = value;
          },
          false,
          "setCurrentStep",
        );
      },
      reset: () => {
        set(
          (state: WizzardState) => {
            state.wizzardState.currentStep = "1";
            state.wizzardState.stepsStatuses = {
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              7: false,
              8: false,
            };
            state.accountDetails = {
              industry: null,
              annualPurchasingVolume: undefined,
              employees: null,
              numberOfSuppliers: null,
              numberOfPurchasers: null,
              typeOfCollaboration: null,
              inhouseProduction: null,
              productionMethods: null,
              certificationsRequired: null,
              preferredSourcingRegions: null,
              currentSourcingRegions: null,
              documentsRequired: null,
              leadTimePartDelivery: null,
              offerTime: null,
              additionalCertifications: null,
              otherProductionMethod: null,
              other_link: null,
              social_links: null,
            };
            state.success = null;
          },
          false,
          "reset",
        );
      },
      setStepStatus(step, value) {
        set(
          (state: WizzardState) => {
            state.wizzardState.stepsStatuses[step] = value;
          },
          false,
          "setStepStatus",
        );
      },
      setSuccess(value) {
        set(
          (state: WizzardState) => {
            state.success = value;
          },
          false,
          "setSuccess",
        );
      },

      setWizardDetails: (orderProperty, value) => {
        set(
          (state: WizzardState) => {
            state.accountDetails = { ...state.accountDetails, [orderProperty]: value };
          },
          false,
          `setWizardDetails/${orderProperty}`,
        );
      },
    })),
    { store: "wizzard-store" },
  ),
);
