import { zodResolver } from "@hookform/resolvers/zod";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { Separator } from "@radix-ui/react-select";
import { useNewUserWizardStore } from "@store/wizard-new-user.store";
import { Button } from "@ui/button";
import { Checkbox } from "@ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as z from "zod";

// eslint-disable-next-line react-hooks/rules-of-hooks
const regionsList = useObjectTranslations("regions_list");

const accountDetailsWizard = z.object({
  currentSourcingRegions: z.array(z.string()).optional(),
  preferredSourcingRegions: z.array(z.string()).optional(),
});
type AccountDetailsFormValues = z.infer<typeof accountDetailsWizard>;

const RegionForm = () => {
  useScrollToTop();
  const { setWizardDetails, setCurrentStep, setStepStatus, accountDetails } =
    useNewUserWizardStore((state) => state);

  const defaultValues: Partial<AccountDetailsFormValues> = {
    currentSourcingRegions: accountDetails?.currentSourcingRegions || undefined,
    preferredSourcingRegions: accountDetails?.preferredSourcingRegions || undefined,
  };

  const form = useForm<AccountDetailsFormValues>({
    resolver: zodResolver(accountDetailsWizard),
    defaultValues,
  });

  async function onSubmit({
    currentSourcingRegions,
    preferredSourcingRegions,
  }: z.infer<typeof accountDetailsWizard>) {
    setWizardDetails("currentSourcingRegions", currentSourcingRegions);
    setWizardDetails("preferredSourcingRegions", preferredSourcingRegions);
    setStepStatus("4", true);
    setCurrentStep("5");
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="max-w-7xl min-h-[20vh] sm:min-h-[34vh] mx-auto sm:px-8 md:px-6 px-4 pb-40 md:pb-0">
            <div className="text-destructive text-xs mb-6">
              {" "}
              * {t("dashboard-any_info_your_not_sure")}
            </div>
            <div className="space-y-10">
              <FormField
                control={form.control}
                name="currentSourcingRegions"
                render={() => (
                  <FormItem>
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-regions_you_are_working_with")}
                    </FormLabel>
                    <div className="flex flex-col md:flex-row gap-4 md:gap-20 pt-4">
                      {Object.entries(regionsList).map(([code, name]) => (
                        <FormField
                          key={code}
                          control={form.control}
                          name="currentSourcingRegions"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={code}
                                className="flex flex-row items-center space-x-3 space-y-0"
                              >
                                <FormControl>
                                  <Checkbox
                                    className="h-5 w-5"
                                    checked={field.value?.includes(code)}
                                    onCheckedChange={(checked) => {
                                      if (code === "WW") {
                                        return checked
                                          ? field.onChange([
                                              "WW",
                                              "DACH",
                                              "IN",
                                              "CH",
                                              "EU",
                                            ])
                                          : field.onChange([]);
                                      }
                                      return checked
                                        ? field.onChange([
                                            ...(field?.value || []).filter(
                                              (value) => value !== "WW",
                                            ),
                                            code,
                                          ])
                                        : field.onChange(
                                            field.value
                                              ?.filter((value) => value !== code)
                                              .filter((vars) => vars !== "WW"),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="text-sm font-normal">
                                  {name}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="preferredSourcingRegions"
                render={() => (
                  <FormItem>
                    <FormLabel className="font-bold font-display sm:text-lg text-sm">
                      {t("dashboard-regions_you_would_like_working_with")}
                    </FormLabel>
                    <div className="flex flex-col md:flex-row gap-4 md:gap-20 pt-4">
                      {Object.entries(regionsList).map(([code, name]) => (
                        <FormField
                          key={code}
                          control={form.control}
                          name={`preferredSourcingRegions`}
                          data-id={`preferredSourcingRegions_${code}`}
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={code}
                                data-id={`preferredSourcingRegions_${code}`}
                                className="flex flex-row items-center space-x-3 space-y-0"
                              >
                                <FormControl>
                                  <Checkbox
                                    className="h-5 w-5"
                                    checked={field.value?.includes(code)}
                                    onCheckedChange={(checked) => {
                                      if (code === "WW") {
                                        return checked
                                          ? field.onChange([
                                              "WW",
                                              "DACH",
                                              "IN",
                                              "CH",
                                              "EU",
                                            ])
                                          : field.onChange([]);
                                      }
                                      return checked
                                        ? field.onChange([
                                            ...(field?.value || []).filter(
                                              (value) => value !== "WW",
                                            ),
                                            code,
                                          ])
                                        : field.onChange(
                                            field.value
                                              ?.filter((value) => value !== code)
                                              .filter((vars) => vars !== "WW"),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="text-sm font-normal">
                                  {name}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="w-full bg-background">
            <Separator className="w-full bg-muted h-[1px]" />
            <div className="flex w-full justify-between p-8">
              <Button
                onClick={() => setCurrentStep("3")}
                variant={"outline"}
                type="button"
              >
                {t("global_go-back-btn")}
              </Button>
              <Button>{t("global_continue-btn")}</Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default RegionForm;
