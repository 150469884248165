export const PAGES_ROUTES = {
  MAIN: "/",
  PROFILE: "/profile",
  NEW_INQURY: "/new-inquiry/:offerId",
  NEW_INQURY_ID_URL: (id: string) => `/new-inquiry/${id}`,
  LOGIN: "/login",
  OFFERS: "/offers",
  SIGN_UP: "/sign-up",
  OFFERS_SUCCESS: "/offer/success",
  OFFERS_NEW: "/offers/new",
  OFFERS_NEW_TEMP: "/offer-new",
  OFFERS_ID: "/offers/:offerId",
  OFFERS_ID_DETAILS: (id: string) => `/offers/${id}`,
  OFFERS_ID_ORDER_PARTS: "/offers/:offerId/single-order/new",
  OFFERS_ID_FRAMEWORK_AGREEMENT: "/offers/:offerId/framework-agreement/new",
  OFFERS_ID_ORDER_PARTS_URL: (id: string) => `/offers/${id}/single-order/new`,
  OFFERS_ID_FRAMEWORK_AGREEMENT_URL: (id: string) =>
    `/offers/${id}/framework-agreement/new`,
  ORDERS: "/orders",
  ORDER: "/orders/:orderId",
  ORDER_ID: (id: string) => `/orders/${id}`,
  FRAMEWORK_AGREEMENTS: "/framework-agreements",
  ORDER_FRAMEWORK_AGREEMENT: "/framework-agreements/:orderId",
  ORDER_ID_NEW_FRAMEWORK_AGREEMENT: "/framework-agreements/:orderId/new",
  ORDER_ID_FRAMEWORK_AGREEMENT_OVERVIEW_URL: (id: string) =>
    `/framework-agreements/${id}`,
  ORDER_ID_NEW_FRAMEWORK_AGREEMENT_URL: (id: string) => `/framework-agreements/${id}/new`,
};
