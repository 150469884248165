import { Link, useLocation } from "react-router-dom";

type NavLinkProps = {
  to: string;
  children: ((props: { active: boolean }) => React.ReactNode) | React.ReactNode;
};

const NavigationLink = ({ to, children }: NavLinkProps) => {
  const location = useLocation();
  const basePath = `/${to.split("/")[1]}`; // Extract the base path
  // Check if the current path starts with the base path
  const active =
    location.pathname === basePath || location.pathname.startsWith(`${basePath}/`);

  return (
    <Link to={to}>
      {typeof children === "function" ? children({ active }) : children}
    </Link>
  );
};

export default NavigationLink;
