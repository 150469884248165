import { cn } from "@lib/utils";
import { spring } from "@utils/animation";
import { cva } from "class-variance-authority";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const sidebarItemVariants = cva(
  "flex items-center md:justify-normal justify-center p-3 font-medium rounded-r-lg transition-all hover:bg-muted/90 text-muted",
  {
    variants: {
      active: {
        default: "hover:bg-muted/70 ",
        active: "bg-muted/10 hover:bg-muted/50",
      },
    },
    defaultVariants: {
      active: "default",
    },
  },
);

const SidebarItem = ({
  icon,
  header,
  active = false,
  className,
}: {
  icon: JSX.Element;
  header: string;
  active?: boolean;
  className?: string | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        "relative",
        sidebarItemVariants({
          active: active ? "active" : "default",
          className,
        }),
      )}
    >
      <span className="mr-0 md:mr-2">{icon}</span>
      <h1 className="sidebar-step-title hidden md:block capitalize text-white font-bold">
        {t(header)}
      </h1>
      {active && (
        <motion.div
          layoutId="line"
          className="absolute border left-0 h-full border-l-4 border-brand z-20"
          transition={spring}
        ></motion.div>
      )}
    </div>
  );
};

export default SidebarItem;
