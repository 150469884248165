export const AUTH_API_ROUTES = {
  LOGIN: `${import.meta.env.VITE_API_DOMAIN}/oauth/token`,
  SIGN_UP: `${import.meta.env.VITE_API_DOMAIN}/dbconnections/signup`,
  USER_INFO: `${import.meta.env.VITE_API_BASE}/customer/userInfo`,
  RESET_PASSWORD: `${import.meta.env.VITE_API_BASE}/customer/account/forgotPassword`,
} as const;

export const OFFER_API_ROUTES = {
  GET_OFFERS: `${import.meta.env.VITE_API_BASE}/customer/offer`,
  GET_LEAD_IN_PROGRESS: (recordId?: string) =>
    `${import.meta.env.VITE_API_BASE}/customer/leadInProgress${
      recordId ? `?recordId=${recordId}` : ""
    }`,
  GET_ORDER_DETAILS_ID: (offerId: string) =>
    `${import.meta.env.VITE_API_BASE}/customer/offer/${offerId}`,
  PLACE_OFFER: `${import.meta.env.VITE_API_BASE}/customer/acceptOfferProcess`,
  UPSERT_OFFER: `${import.meta.env.VITE_API_BASE_V2}/customer/lead/upsert`,
} as const;

export const ORDER_API_ROUTES = {
  GET_ORDERS: `${import.meta.env.VITE_API_BASE}/customer/order`,
  GET_ORDER_ID: (id: string) => `${import.meta.env.VITE_API_BASE}/customer/order/${id}`,
} as const;
export const FA_API_ROUTES = {
  GET_FAS: `${import.meta.env.VITE_API_BASE}/customer/frameworkAgreement`,
  GET_FA: (id: string) =>
    `${import.meta.env.VITE_API_BASE}/customer/frameworkAgreement/${id}`,
  CREATE_FA_BATCH_ORDER: `${import.meta.env.VITE_API_BASE}/customer/createFaBatchOrder`,
} as const;

export const USER_API_ROUTES = {
  UPDATE_DEFAULT_CONTACTS: `${
    import.meta.env.VITE_API_BASE
  }/customer/updateDefaultContacts`,
  UPDATE_LANGUAGE: `${import.meta.env.VITE_API_BASE}/customer/updateLanguage`,
  CHECK_EMAIL: (email: string) =>
    `${import.meta.env.VITE_API_BASE}/customer/checkContact?email=${email}`,
  INVITE_CONTACT: `${import.meta.env.VITE_API_BASE}/customer/inviteContact`,
  UPDATE_CONTACT_PORTAL_ROLE: `${
    import.meta.env.VITE_API_BASE
  }/customer/updateContactPortalRole`,
} as const;

export const CUSTOMER_API_ROUTES = {
  HOME_INSIGHT: `${import.meta.env.VITE_API_BASE}/customer/homeInsights`,
  MESSAGES: `${import.meta.env.VITE_API_BASE}/customer/messages`,
  UPDATE_MESSAGE_STATUS: `${import.meta.env.VITE_API_BASE}/customer/markAsRead`,
  UPLOAD_URL: `${import.meta.env.VITE_API_BASE}/customer/uploadUrl`,
  UPLOAD_URL_LEAD: `${import.meta.env.VITE_API_BASE_V2}/customer/lead/uploadUrl`,
  TAX: `${import.meta.env.VITE_API_BASE}/customer/tax`,
  SIGN_UP: `${import.meta.env.VITE_API_BASE}/customer/signUp`,
  UPDATE_COMPANY_PROFILE: `${
    import.meta.env.VITE_API_BASE
  }/customer/updateCompanyProfile`,
  UPLOAD_COMPLAINT_FILE: `${import.meta.env.VITE_API_BASE}/customer/complaintFile`,
} as const;

export const CUSTOMER_PORTAL_BASE_LEGACY_URL = {
  base:
    import.meta.env.MODE === "development"
      ? "https://portal-stg.cnc24.com"
      : "https://portal.cnc24.com",
  inquryWizard:
    import.meta.env.MODE === "development"
      ? "https://inquiry-stg.cnc24.com"
      : "https://inquiry.cnc24.com",
} as const;

export const CUSTOMER_PORTAL_URLS = {
  INQUERIES: `${CUSTOMER_PORTAL_BASE_LEGACY_URL.base}/offers?type=All`,
  OFFER_ID_COMPONENTS: (id: string) =>
    `${CUSTOMER_PORTAL_BASE_LEGACY_URL.base}/offers/${id}`,
  ORDER_ID_COMPONENTS: (id: string) =>
    `${CUSTOMER_PORTAL_BASE_LEGACY_URL.base}/orders/${id}/components`,
  ORDERS: `${CUSTOMER_PORTAL_BASE_LEGACY_URL.base}/orders?type=Open`,
  FRAMEWORK_AGREEMENTS: `${CUSTOMER_PORTAL_BASE_LEGACY_URL.base}/framework-agreements?type=Active`,
  FRAMEWORK_AGREEMENT_ID: (id: string) =>
    `${CUSTOMER_PORTAL_BASE_LEGACY_URL.base}/framework-agreements/${id}/components`,
} as const;

export const SEARCH_URL = {
  SEARCH: (string: string) =>
    `${
      import.meta.env.VITE_API_SEARCH_GATEWAY
    }/search/suggest/companywithaddress/${string}`,
} as const;
