import { Icons } from "@components/icons";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { FrameworkFile } from "@models/framework-agreement.model";
import { Button } from "@ui/button";
import { trackInquiryDrawingsDownloaded } from "@utils/tracking";
import { t } from "i18next";
interface ZoomPartImageProps {
  partName?: string;
  imageUrl?: string;
  fileDownloadUrl?: string | null;
  articleNumber?: string | null;
  materialGroup: string;
  material: string | null;
  nameTechnicalDrawing: string | null;
  revision: string | null;
  materialCertificate: string | null;
  tolorence?: string | null;
  productionMethod: string[] | null;
  surface: string[] | null;
  comments: string | null;
  surfaceDetailsMap?: { [key: string]: string };
  allFiles?: FrameworkFile[];
}
// eslint-disable-next-line react-hooks/rules-of-hooks
const materialGroupObj = useObjectTranslations("materials");
// eslint-disable-next-line react-hooks/rules-of-hooks
const certificatesObj = useObjectTranslations("certificates");
// eslint-disable-next-line react-hooks/rules-of-hooks
const productionMethodObj = useObjectTranslations("production-method");

const ZoomPartImage = ({
  partName,
  articleNumber,
  materialGroup,
  material,
  imageUrl,
  nameTechnicalDrawing,
  revision,
  materialCertificate,
  tolorence,
  productionMethod,
  surface,
  comments,
  surfaceDetailsMap,
  allFiles,
}: ZoomPartImageProps) => {
  return (
    <div className="max-w-7xl">
      <div className="mb-4">
        <h1 className="sm:text-4xl text-2xl font-bold font-display">{partName}</h1>
        {articleNumber && (
          <h2 className="text-sm font-bold text-muted-foreground">
            {articleNumber || "-"}
          </h2>
        )}
      </div>
      {comments ? (
        <div className="p-6 border rounded-lg flex items-center space-x-4 bg-[#FFF2E8] mb-2">
          <Icons.warningCircle className="text-[#FD7E14]" />
          <span className="text-sm space-x-1">
            <span className="font-bold font-display">{t("please_note")}:</span>
            <span>{comments}</span>
          </span>
        </div>
      ) : null}

      <div>
        {imageUrl ? (
          <img
            className="w-full h-96 object-contain"
            loading="lazy"
            decoding="async"
            src={imageUrl}
            alt="Part thumbnail"
          />
        ) : (
          <div className="h-96 w-full flex items-center justify-center bg-secondary rounded-md">
            <Icons.removeImageIcon className=" size-8 sm:size-20 mr-2" />
          </div>
        )}
      </div>
      {allFiles && allFiles.length > 0 ? (
        <div className="flex justify-between items-center mt-6">
          <div className="flex flex-col items-center gap-2 w-full">
            {allFiles?.map((file, index) => (
              <a
                key={index}
                className="w-full"
                target="_blank"
                href={file.fileDownloadUrl || file.fileThumbnailUrl}
                download={true}
                onClickCapture={trackInquiryDrawingsDownloaded}
                rel="noreferrer"
              >
                <Button
                  onClick={trackInquiryDrawingsDownloaded}
                  variant={"secondaryAlt"}
                  className="w-full flex justify-between"
                >
                  <span>{file.fileName} </span> <Icons.download />
                </Button>
              </a>
            ))}
          </div>
        </div>
      ) : null}
      <div className="text-sm grid gap-8 mt-6 grid-cols-3">
        <div className="space-y-2">
          <div className="text-muted-foreground">
            {t("parts-overview_article-number")}
          </div>
          <div>{articleNumber || "-"}</div>
        </div>
        <div className="space-y-2">
          <div className="text-muted-foreground">{t("details_drawing-number")}</div>
          <div>{nameTechnicalDrawing || "-"}</div>
        </div>
        <div className="space-y-2">
          <div className="text-muted-foreground">{t("details_revision-number")}</div>
          <div>{revision || "-"}</div>
        </div>
        <div className="space-y-2">
          <div className="text-muted-foreground">{t("details_certificates")}</div>
          {materialCertificate ? (
            <div>{certificatesObj[materialCertificate]}</div>
          ) : (
            <div>-</div>
          )}
        </div>
        {tolorence && (
          <div className="space-y-2">
            <div className="text-muted-foreground">{t("tolerances")}</div>
            <div>{tolorence || "-"}</div>
          </div>
        )}
        <div className="space-y-2">
          <div className="text-muted-foreground">{t("details_material")}</div>
          <div>
            {" "}
            {materialGroupObj[materialGroup?.replace(/\s/g, "")]}
            {material && " -"} {material || ""}
          </div>
        </div>
        <div className="space-y-2">
          <div className="text-muted-foreground">{t("production_process")}</div>
          <div>
            {productionMethod
              ?.map((prodMethod) => productionMethodObj[prodMethod?.replace(/\s/g, "")])
              .join(" ") || "-"}
          </div>
        </div>
        {surfaceDetailsMap && (
          <div className="space-y-2">
            <div className="text-muted-foreground">{t("surface_treatment")}</div>
            <ul className="list-disc list-outside ml-4">
              {surface?.map((surf, index) => {
                return <li key={index}>{`${surf} (${surfaceDetailsMap[surf]})`}</li>;
              }) || "-"}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZoomPartImage;
