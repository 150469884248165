import { COUNTRIES } from "@assets/countries";
import { Icons } from "@components/icons";
import { SingleDatePicker } from "@components/single-date-picker";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetFramerworkAgreementId } from "@hooks/useGetFrameworkAgreementId";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { DAY_MONTH_YEAR_FORMAT, isWithinRange } from "@lib/date";
import { cn } from "@lib/utils";
import * as CommonService from "@services/common.service";
import { useUserStore } from "@store/user.store";
import { useFrameworkAgreementWizzardState } from "@store/wizard-framework-agreement.store";
import { Button } from "@ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/select";
import { Separator } from "@ui/separator";
import { Textarea } from "@ui/textarea";
import { addDays, format, isBefore, isEqual, parse, subDays } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as z from "zod";

// eslint-disable-next-line react-hooks/rules-of-hooks
const countiriesTraslation = useObjectTranslations("countries");

export const EXTRA_DAYS = 2;
const orderDetailsSchema = z.object({
  shippingCompany: z.string().nonempty(t("error_field-required") as string),
  shippingStreet: z.string().nonempty(t("error_field-required") as string),
  shippingCity: z.string().nonempty(t("error_field-required") as string),
  shippingPostalCode: z.string().nonempty(t("error_field-required") as string),
  shippingCountry: z.string().nonempty(t("error_field-required") as string),
  shippingAddressAdditionalInfo: z.string().optional(),
  billingCompany: z.string().nonempty(t("error_field-required") as string),
  billingStreet: z.string().nonempty(t("error_field-required") as string),
  billingCity: z.string().nonempty(t("error_field-required") as string),
  billingPostalCode: z.string().nonempty(t("error_field-required") as string),
  billingCountry: z.string().nonempty(t("error_field-required") as string),
  billingAdditionalInfo: z.string().optional(),
  taxNumber: z.string().optional(),
  comments: z.string().optional(),
  contactOrderConfirmation: z.string().optional(),
  contactInvoicing: z.string().optional(),
  contactPurchasing: z.string().optional(),
  deliveryDate: z.string().nonempty(t("error_field-required") as string),
  reference: z.string().nonempty(t("error_field-required") as string),
});
type OrderDetailsFormValues = z.infer<typeof orderDetailsSchema>;

const OrderDetailsForm = () => {
  useScrollToTop();
  const params = useParams();
  const { data: offerDetails } = useGetFramerworkAgreementId(params.orderId as string);
  const {
    data: offersLines,
    setOrderDetails,
    order,
    changeDefaultContact,
    defaultContacts,
    changeAddress,
    addresses,
    setCurrentStep,
    setStepStatus,
    setFrameworkDetails,
    warningOnSelectedDeliveryDate,
    setWarningOnSelectedDeliveryDate,
    setCerficateLines,
  } = useFrameworkAgreementWizzardState((state) => state);
  const { user } = useUserStore();
  const [uploadingDocs, setUploadingDocs] = useState({ isPending: false, error: "" });
  const productionDaysOnAllLineItems = offersLines?.map((item) => item.minProductionDays);
  // minimum production days needed
  const minProductionDay = productionDaysOnAllLineItems
    ? Math.max(...productionDaysOnAllLineItems)
    : EXTRA_DAYS;
  // Calculate min delivery date
  const minDeliveryDate = addDays(new Date(), minProductionDay);

  const defaultValues: Partial<OrderDetailsFormValues> = {
    shippingCompany:
      addresses.shippingAddress.companyName || offerDetails?.data?.shippingCompany || "",
    shippingStreet:
      addresses.shippingAddress.street || offerDetails?.data?.shippingStreet || "",
    shippingPostalCode:
      addresses.shippingAddress.postalCode ||
      offerDetails?.data?.shippingPostalCode ||
      "",
    shippingCity:
      addresses.shippingAddress.city || offerDetails?.data?.shippingCity || "",
    shippingCountry:
      addresses.shippingAddress.country || offerDetails?.data?.shippingCountry || "",
    shippingAddressAdditionalInfo:
      addresses.shippingAddress.additionalInfo ||
      offerDetails?.data?.shippingAdditionalInfo ||
      "",
    billingCompany:
      addresses.billingAddress.companyName || offerDetails?.data?.billingCompany || "",
    billingStreet:
      addresses.billingAddress.street || offerDetails?.data?.billingStreet || "",
    billingPostalCode:
      addresses.billingAddress.postalCode || offerDetails?.data?.billingPostalCode || "",
    billingCity: addresses.billingAddress.city || offerDetails?.data?.billingCity || "",
    billingCountry:
      addresses.billingAddress.country || offerDetails?.data?.billingCountry || "",
    billingAdditionalInfo:
      addresses.billingAddress.additionalInfo ||
      offerDetails?.data?.billingAdditionalInfo ||
      "",
    taxNumber: addresses.billingAddress.taxNumber || offerDetails?.data?.taxNumber || "",
    deliveryDate: order.deliveryDate || format(minDeliveryDate, DAY_MONTH_YEAR_FORMAT),
    contactInvoicing: defaultContacts?.contactInvoicing || user?.contactInvoicing || "",
    contactPurchasing:
      defaultContacts?.contactPurchasing || user?.contactPurchasing || "",
    contactOrderConfirmation:
      defaultContacts?.contactOrderConfirmation || user?.contactOrderConfirmation || "",
  };

  const form = useForm<OrderDetailsFormValues>({
    resolver: zodResolver(orderDetailsSchema),
    defaultValues,
  });
  async function onSubmitOrderDetails({
    shippingCompany,
    reference,
    comments,
    contactInvoicing,
    contactOrderConfirmation,
    contactPurchasing,
    billingCity,
    billingPostalCode,
    billingCompany,
    billingCountry,
    billingAdditionalInfo,
    billingStreet,
    taxNumber,
    shippingCity,
    shippingStreet,
    shippingCountry,
    shippingPostalCode,
    shippingAddressAdditionalInfo,
    deliveryDate,
  }: z.infer<typeof orderDetailsSchema>) {
    setOrderDetails("reference", reference);
    setOrderDetails("comments", comments || "");
    setOrderDetails("deliveryDate", deliveryDate);
    setFrameworkDetails(
      (offersLines?.filter((offer) => offer.selected) || []).map((offer) => {
        return {
          faLineId: offer.frameworkLineId,
          quantity: offer.setQuantity,
          totalPrice: offer.calculatedTotalPrice,
        };
      }),
    );
    setCerficateLines(
      (
        offersLines?.filter(
          (offer) => offer.selected && offer.certificateLines?.length > 0,
        ) || []
      )?.flatMap((certificateLine) => {
        return certificateLine.certificateLines
          .filter((cert) => cert.offerLineId)
          .map((certificate) => ({
            offerLineId: certificate.offerLineId,
            quantity: certificate.quantity,
          }));
      }),
    );
    changeDefaultContact("contactInvoicing", contactInvoicing);
    changeDefaultContact("contactOrderConfirmation", contactOrderConfirmation);
    changeDefaultContact("contactPurchasing", contactPurchasing);
    changeAddress("billingAddress", "city", billingCity);
    changeAddress("billingAddress", "postalCode", billingPostalCode);
    changeAddress("billingAddress", "companyName", billingCompany);
    changeAddress("billingAddress", "country", billingCountry);
    changeAddress("billingAddress", "additionalInfo", billingAdditionalInfo || "");
    changeAddress("billingAddress", "street", billingStreet);
    changeAddress("billingAddress", "taxNumber", taxNumber);
    changeAddress("shippingAddress", "city", shippingCity);
    changeAddress("shippingAddress", "postalCode", shippingPostalCode);
    changeAddress("shippingAddress", "companyName", shippingCompany);
    changeAddress("shippingAddress", "country", shippingCountry);
    changeAddress(
      "shippingAddress",
      "additionalInfo",
      shippingAddressAdditionalInfo || "",
    );
    changeAddress("shippingAddress", "street", shippingStreet);
    setStepStatus("2", true);
    setCurrentStep("3");
  }
  useEffect(() => {
    handleOnValueDateChange();
  }, []);
  const handleSearchSelectedDates = (selectedDate: Date) => {
    form.setValue("deliveryDate", format(selectedDate, DAY_MONTH_YEAR_FORMAT));
    setOrderDetails("deliveryDate", format(selectedDate, DAY_MONTH_YEAR_FORMAT));
  };
  const handleOnValueDateChange = (changedDate?: Date) => {
    if (!changedDate) {
      return;
    }

    // Check if selected date is after min delivery
    const selectedDateAfterMinDeliveryDate =
      isBefore(minDeliveryDate, subDays(changedDate, -1)) ||
      isEqual(minDeliveryDate, changedDate);

    // Calculate max delivery date
    const maxDeliveryDate = addDays(minDeliveryDate, 30);

    // Check if selected date is before max delivery
    const selectedDateAfterMaxDeliveryDate =
      isBefore(changedDate, maxDeliveryDate) || isEqual(changedDate, maxDeliveryDate);

    // Check if selected date is higher then max delivery date or lower then min delivery date
    const dateValidation =
      selectedDateAfterMinDeliveryDate && selectedDateAfterMaxDeliveryDate;
    setWarningOnSelectedDeliveryDate(!dateValidation);
    return {
      warning: !dateValidation,
      warningText: "wizard-ord-details_desired-delivery-note_datepicker",
    };
  };

  const isDayHighlighted = (date: Date) => {
    const endDate = subDays(addDays(new Date(), minProductionDay), 1);
    return isWithinRange(date, {
      from: subDays(new Date(), 1),
      to: endDate,
    });
  };

  const handleUploadOrderDocuments = async (acceptedFiles: File[]) => {
    setUploadingDocs({ isPending: true, error: "" });
    acceptedFiles.forEach(async (file) => {
      const payload = {
        recordId: params.id as string,
        fileName: file.name,
        file: file,
      };
      const { data: uploadedFile } = await CommonService.uploadUrl(payload);
      if (uploadedFile) {
        setOrderDetails("files", [
          ...useFrameworkAgreementWizzardState.getState().order.files,
          { ...uploadedFile, file: URL.createObjectURL(file) },
        ]);
        return;
      }
    });
    setUploadingDocs({ isPending: false, error: "" });
  };
  return (
    <div className="pb-40">
      <div className="space-y-2 mb-8">
        <h6 className="text-sm text-muted-foreground font-bold font-display">
          {t("dashboard-framework_order")} {`(${offerDetails?.data?.faNr})`}
        </h6>
        <h1 className="font-bold md:text-2xl text-xl font-display">
          {t("dashboard-add_or_update_info")}
        </h1>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitOrderDetails)}
          className="grid md:grid-cols-2 gap-4 grid-cols-1"
        >
          <div className="space-y-10">
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("wizard-ord-details_shipping-address")}
              </h3>
              <FormField
                control={form.control}
                name="shippingCompany"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-order-details_company-name")}{" "}
                      <span className="text-destructive ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="shippingAddressAdditionalInfo"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("wizard-ord-details_additional-info")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="shippingStreet"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-ord-details_street")}{" "}
                      <span className="text-destructive ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-3 gap-2">
                <FormField
                  control={form.control}
                  name="shippingPostalCode"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel>
                        {t("wizard-ord-details_plz")}{" "}
                        <span className="text-destructive ml-1">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="shippingCity"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>
                        {t("wizard-ord-details_city")}{" "}
                        <span className="text-destructive ml-1">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="shippingCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormLabel>
                          {t("wizard-ord-details_country")}{" "}
                          <span className="text-destructive ml-1">*</span>
                        </FormLabel>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent className="max-h-96">
                          {Object.entries(COUNTRIES).map(([code, name]) => {
                            if (code === "AT") {
                              return (
                                <React.Fragment key={code}>
                                  <SelectItem value={code}>
                                    {countiriesTraslation
                                      ? countiriesTraslation[code]
                                      : name}
                                  </SelectItem>
                                  <Separator orientation="horizontal" />
                                </React.Fragment>
                              );
                            }
                            return (
                              <SelectItem key={code} value={code}>
                                {countiriesTraslation ? countiriesTraslation[code] : name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("wizard-ord-details_billing-address")}
              </h3>
              <FormField
                control={form.control}
                name="billingCompany"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-order-details_company-name")}{" "}
                      <span className="text-destructive ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingAdditionalInfo"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("wizard-ord-details_additional-info")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingStreet"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-ord-details_street")}{" "}
                      <span className="text-destructive ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-3 gap-2">
                <FormField
                  control={form.control}
                  name="billingPostalCode"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel>
                        {t("wizard-ord-details_plz")}{" "}
                        <span className="text-destructive ml-1">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="billingCity"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>
                        {t("wizard-ord-details_city")}{" "}
                        <span className="text-destructive ml-1">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="billingCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormLabel>
                          {t("wizard-ord-details_country")}{" "}
                          <span className="text-destructive ml-1">*</span>
                        </FormLabel>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent className="max-h-96">
                          {Object.entries(COUNTRIES).map(([code, name]) => {
                            if (code === "AT") {
                              return (
                                <React.Fragment key={code}>
                                  <SelectItem value={code}>
                                    {countiriesTraslation
                                      ? countiriesTraslation[code]
                                      : name}
                                  </SelectItem>
                                  <Separator orientation="horizontal" />
                                </React.Fragment>
                              );
                            }
                            return (
                              <SelectItem key={code} value={code}>
                                {countiriesTraslation ? countiriesTraslation[code] : name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="taxNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("dashboard-vat_id")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="space-y-10">
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("wizard-ord-details_your-order-info")}
              </h3>
              <FormField
                defaultValue={order.reference}
                control={form.control}
                name="reference"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-ord-details_internal-ord-num")}{" "}
                      <span className="text-destructive ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="deliveryDate"
                render={() => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-ord-details_desired-delivery-date")}{" "}
                      <span className="text-destructive ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <SingleDatePicker
                        dateProps={
                          order.deliveryDate
                            ? parse(order.deliveryDate, DAY_MONTH_YEAR_FORMAT, new Date())
                            : minDeliveryDate
                        }
                        onSave={handleSearchSelectedDates}
                        isDayHighlighted={isDayHighlighted}
                        onValueChange={handleOnValueDateChange}
                        disabled={{ before: new Date() }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {warningOnSelectedDeliveryDate && (
                <div className="border rounded-lg p-6 bg-light-yellow flex justify-center items-center gap-4">
                  <div>
                    <Icons.warning className="fill-brand text-white" />
                  </div>
                  <p className="text-sm">{t("wizard-copy_delivery-date-warning")}</p>
                </div>
              )}
              <FormField
                defaultValue={order.comments || ""}
                control={form.control}
                name="comments"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("wizard-ord-details_comment")}</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className={cn("col-span-2 justify-items-end")}>
                <div className="mb-2 text-sm">
                  {t("wizard-ord-details_attach-order-doc")}
                </div>
                <Dropzone
                  onDrop={(acceptedFiles) => handleUploadOrderDocuments(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps({ accept: ".pdf" })}
                        disabled={uploadingDocs.isPending}
                      />
                      <div className="rounded-2xl p-8 border border-dashed bg-secondary flex justify-center items-center flex-col text-center space-y-4">
                        <div className="font-bold font-display">
                          {t("dashboard-drag_drop_doc")}
                        </div>
                        <div className="font-sm">{t("dashboard-global_or")}</div>
                        <Button type="button" disabled={uploadingDocs.isPending}>
                          {uploadingDocs.isPending ? (
                            <Icons.spinner className="h-4 w-4 animate-spin mr-2" />
                          ) : (
                            <Icons.upload className="w-4 h-4 mr-2" />
                          )}

                          <div className="font-bold font-display">
                            {t("dashboard-uploadnewdocuments")}
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="space-y-3">
                  <AnimatePresence initial={false}>
                    {order.files.map(({ fileId, fileName }) => (
                      <motion.div
                        initial={{ opacity: 0, y: 50, scale: 0.3 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.3 } }}
                        key={fileId}
                        className="flex justify-between items-center mt-2"
                      >
                        <div className="flex space-x-2">
                          <Icons.page className="w-4 h-4" />
                          <span className="text-sm">{fileName}</span>
                        </div>
                        <Button
                          type="button"
                          variant={"ghost"}
                          onClick={() => {
                            setOrderDetails(
                              "files",
                              useFrameworkAgreementWizzardState
                                .getState()
                                .order.files.filter((file) => file.fileId !== fileId),
                            );
                          }}
                        >
                          <Icons.trash className="w-4 h-4" />
                        </Button>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("account-tab_contacts")}
              </h3>
              <div className="grid sm:grid-cols-2 sm:gap-2 gap-0 space-y-2 sm:space-y-0 grid-cols-1">
                <FormField
                  control={form.control}
                  name="contactPurchasing"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("contacts_purchasing")}</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={
                          defaultContacts?.contactPurchasing ||
                          user?.contactPurchasing ||
                          undefined
                        }
                      >
                        <FormControl>
                          <SelectTrigger className="h-[3rem] truncate">
                            <SelectValue
                              placeholder={t("contacts_contacts-dropdown-placeholder")}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="max-h-96">
                          {user?.contacts.map((contact) => {
                            return (
                              <SelectItem value={contact.id} key={contact.id}>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.firstname} {contact.lastname}
                                </div>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.email}
                                </div>
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="contactInvoicing"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("contacts_invoicing")}</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={
                          defaultContacts?.contactInvoicing ||
                          user?.contactInvoicing ||
                          undefined
                        }
                      >
                        <FormControl>
                          <SelectTrigger className="h-[3rem] truncate">
                            <SelectValue
                              placeholder={t("contacts_contacts-dropdown-placeholder")}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="max-h-96">
                          {user?.contacts.map((contact) => {
                            return (
                              <SelectItem value={contact.id} key={contact.id}>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.firstname} {contact.lastname}
                                </div>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.email}
                                </div>
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="contactOrderConfirmation"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("contacts_order-confirm")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={
                        defaultContacts?.contactOrderConfirmation ||
                        user?.contactOrderConfirmation ||
                        undefined
                      }
                    >
                      <FormControl>
                        <SelectTrigger className="h-[3rem] truncate">
                          <SelectValue
                            placeholder={t("contacts_contacts-dropdown-placeholder")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="max-h-96">
                        {user?.contacts.map((contact) => {
                          return (
                            <SelectItem value={contact.id} key={contact.id}>
                              <div className="text-left sm:text-sm text-xs">
                                {contact.firstname} {contact.lastname}
                              </div>
                              <div className="text-left sm:text-sm text-xs">
                                {contact.email}
                              </div>
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex fixed gap-4 sm:p-8 p-6 left-0 sm:left-[4rem] md:left-[16rem] right-0 bottom-0 border-t bg-background md:w-[calc(100%-16rem)] sm:w-[calc(100%-4rem)] w-full z-20 justify-between flex-col sm:flex-row">
            <Button
              disabled={uploadingDocs.isPending}
              onClick={() => setCurrentStep("1")}
              variant={"outline"}
              type="button"
            >
              {t("global_go-back-btn")}
            </Button>

            <Button disabled={uploadingDocs.isPending}>{t("global_continue-btn")}</Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default OrderDetailsForm;
