import { FA_API_ROUTES } from "@config/api";
import { fetchWithToken, postWithToken } from "@lib/request";
import {
  CreateFABatchOrderResponse,
  FASearchValue,
  FrameworkAgreement,
  FrameworkAgreementIDResponse,
  FrameworkAgreementOrder,
} from "@models/framework-agreement.model";
import { Addresses, DefaultContacts, FileObject } from "@models/offer";

interface GetFrameworkAgreementsResponse {
  data: FrameworkAgreement[] | null;
  status: number;
}
export interface GetCreateFABatchOrderResponse {
  data: CreateFABatchOrderResponse;
  status: number;
}
interface GetFrameworkAgreementResponse {
  data: FrameworkAgreementIDResponse | null;
  status: number;
}

type FASearchType = {
  label: string;
  value: FASearchValue;
};

export const faSearchValues: FASearchType[] = [
  { label: "status-active", value: "Active" },
  { label: "status-cancelled", value: "Cancelled" },
  { label: "status-waiting-for-confirmation", value: "WaitingForConfirmation" },
  { label: "status-completed", value: "Completed" },
  { label: "status-all", value: "All" },
];

export interface FaParams {
  startDate?: string | null;
  endDate?: string | null;
  type: FASearchValue | null;
  searchFilter?: string | null;
}

export interface CreateFABatchOrderPayload {
  defaultContacts: DefaultContacts;
  addresses: Addresses;
  faId: string;
  faReference: string;
  deliveryDate: string | null | undefined;
  files: FileObject[];
  faLines: FrameworkAgreementOrder[];
  totalPrice: number;
}

export async function getFrameworkAgreements({ params }: { params?: FaParams }): Promise<{
  data: FrameworkAgreement[] | null;
  error?: string;
}> {
  let response: FrameworkAgreement[] | null = null;
  let errorResponse: string | undefined;
  const url = new URL(FA_API_ROUTES.GET_FAS);
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === "object") return;
      url.searchParams.append(key, value);
    });
  }
  try {
    const { data: result } = await fetchWithToken<GetFrameworkAgreementsResponse>(
      url.toString(),
    );
    if (result?.data) {
      response = result.data;
    }
  } catch (error: any) {
    errorResponse = error.message;
  }
  return {
    data: response,
    error: errorResponse,
  };
}

export async function getFrameworkAgreementWithId(id: string): Promise<{
  data: FrameworkAgreementIDResponse | null;
  error?: string;
}> {
  try {
    const { data: result } = await fetchWithToken<GetFrameworkAgreementResponse>(
      FA_API_ROUTES.GET_FA(id),
    );
    if (result?.data) {
      return {
        data: result.data,
        error: undefined,
      };
    }
    return {
      data: null,
      error: "not found",
    };
  } catch (error: any) {
    return {
      data: null,
      error: error.message,
    };
  }
}

export async function createFaBatchOrder(payload: CreateFABatchOrderPayload): Promise<{
  data: GetCreateFABatchOrderResponse | null;
  error?: string;
}> {
  try {
    const { data: result } = await postWithToken<GetCreateFABatchOrderResponse>(
      FA_API_ROUTES.CREATE_FA_BATCH_ORDER,
      undefined,
      payload,
    );
    if (result?.data) {
      return {
        data: result.data,
        error: undefined,
      };
    }
    return {
      data: null,
      error: "not found",
    };
  } catch (error: any) {
    return {
      data: null,
      error: error.message,
    };
  }
}
