import * as CookieAccess from "@access/cookie-storage";
import { storageKeys } from "@config/common";
import { PAGES_ROUTES } from "@config/routes";
import * as Auth from "@services/auth.service";
import { useUserStore } from "@store/user.store";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

export const useAuth = () => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl = location.pathname;
  const {
    data: user,
    isPending,
    error,
    refetch,
  } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      return await Auth.getUser();
    },
  });
  const [sessionExpired, setSessionExpired] = useState<boolean | undefined>();
  const [token, setToken] = useState<string | undefined>();

  useEffect(() => {
    const fetchToken = async () => {
      const { sessionAvailablity: hasSessionExpired, token } = await Auth.sessionCheck();
      setToken(token);
      if (hasSessionExpired) {
        CookieAccess.setCookie(storageKeys.userId, "");
        CookieAccess.setCookie(storageKeys.token, "");
        CookieAccess.setCookie(storageKeys.returnUrl, returnUrl);
        navigate(PAGES_ROUTES.LOGIN);
      } else {
        setSessionExpired(false);
      }
    };
    fetchToken();
  }, [navigate]);

  useEffect(() => {
    if (!sessionExpired && token) {
      refetch();
    }
  }, [sessionExpired, token]);

  useEffect(() => {
    if (user && user.data) {
      setUser(user.data);
    }
    if (user?.error) {
      toast.error(user?.error, { description: t("login_session-expired-msg") });
      CookieAccess.setCookie(storageKeys.userId, "");
      navigate(PAGES_ROUTES.LOGIN);
      return;
    } else if (location.pathname === PAGES_ROUTES.LOGIN) {
      navigate(returnUrl);
    }
  }, [user]);

  return {
    user: user?.data,
    error,
    isLoading: isPending,
    sessionExpired,
  };
};
